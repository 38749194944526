import {
  OskarsSylwan,
  Ossy,
  PlexusSanitas,
  Minimal,
  Standard,
  Tepit,
  Gaming,
  Glass
} from 'themes'

export default {
  gaId: 'G-6CL8MVRG51',
  theme: 'Ossy',
  themes: {
    Glass,
    OskarsSylwan,
    Ossy,
    PlexusSanitas,
    Minimal,
    Standard,
    Tepit,
    Gaming
  },
  apiUrl: process.env.REACT_CMS_API || 'https://api.ossy.se/api/v0',
  // apiUrl: 'http://localhost:3001/api/v0',
  // devMode: true,
}
