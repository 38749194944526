import { useNavigate } from 'react-router-dom'
import { useAuthentication, useWorkspace, useWorkspaces } from '@ossy/cms-client-react'
import {
  Button,
  Stack,
  Dropdown,
  ContextMenu,
  Icon2,
  PageSection,
  useDocumentTitle,
  View
} from 'design-system'
import { css } from 'glamor'
import { SidebarPrimary } from '../navigation/SidebarPrimary'

const contentStyles = css({
  '::scrollbar': {
    display: 'none'
  }
})

export const WorkspaceLayout = ({ children }) => {
  const { workspaces = [] } = useWorkspaces()
  const navigate = useNavigate()
  const { signOff } = useAuthentication()
  const { workspace } = useWorkspace()

  const navigation = [
    { id: 'resources-nav', label: 'Resources', href: `@resources`, variant: 'command' },
    // { id: 'overview-nav', label: 'Overview', href: `@planner/overview`, variant: 'command' },
    // { id: 'tasks-nav', label: 'Tasks', href: `@planner/tasks`, variant: 'command' },
    // { id: 'timesheet-nav', label: 'Timesheet', href: `@planner/timesheet`, variant: 'command' },
    // { id: 'contracts-nav', label: 'Contracts', href: `@planner/contracts`, variant: 'command' },
    // { id: 'employees-nav', label: 'Employees', href: `@planner/employees`, variant: 'command' },
    // { id: 'forecast-nav', label: 'Forecast', href: `@planner/forecast`, variant: 'command' },
    // { id: 'jobs-nav', label: 'Jobs', href: `@resources?location=%2F%40ossy%2Fjobs%2F`, variant: 'command' },
    { id: 'settings-nav', label: 'Settings', href: `@workspace/settings`, variant: 'command' },
  ]

  useDocumentTitle(workspace.name || 'loading workspace')

  return (
    <Stack horizontal bordered surface="base" style={{ height: '100dvh', '--size': '56px' }} >

        <SidebarPrimary />

        <Stack.Item fill surface="primary">

          <PageSection
            id="header"
            as="header"
            maxWidth="full"
            style={{
              borderBottom: '1px solid var(--separator-primary)',
              height: 'var(--size, 64px)',
              padding: 'var(--space-s)',
              flexShrink: '0'
            }}
          >

            <View layout="row" style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 'var(--space-s)',
            }}>

            <View layout="row" gap="s">
              <Dropdown trigger={(
                  <Button prefix="select" variant="link" style={{ fontWeight: '900' }}>
                    {workspace.name}
                  </Button>
              )}>
                <ContextMenu>
                  {workspaces.map(({ id, name }) => (
                    <ContextMenu.Item onClick={() => navigate(`/${id}`)}>
                      {name}
                    </ContextMenu.Item>
                  ))}
                </ContextMenu>
              </Dropdown>

              {navigation.map(x => <Button key={x.id} {...x}/>)}

            </View>

            <Stack.Item fill />

            <Button variant="link" suffix="overflow" label="theme"/>

            <Dropdown trigger={<Button variant="link" suffix="profile">Profile</Button>}>
              <ContextMenu>

                <ContextMenu.Item onClick={() => navigate('/profile/workspaces')}>
                  <Icon2 name="controller"/>
                  My Workspaces
                </ContextMenu.Item>

                <ContextMenu.Item onClick={() => navigate('/profile/api-tokens')}>
                  <Icon2 name="keyhole"/>
                  Api tokens
                </ContextMenu.Item>

                <ContextMenu.Separator />

                <ContextMenu.Item onClick={signOff}>
                  <Icon2 name="log-out"/>
                  Log out
                </ContextMenu.Item>

              </ContextMenu>
            </Dropdown>
          </View>

        </PageSection>

        <main data-surface="primary" {...contentStyles} style={{ overflowX: 'hidden', overflowY: 'auto', height: '100%' }}>
          {children}
        </main>
        </Stack.Item>

    </Stack>
  )
}
