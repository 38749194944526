import { useState } from 'react'
import { useNavigate, useParams, createSearchParams, useSearchParams } from 'react-router-dom'
import { set, lensPath } from 'ramda'
import { useResources, useResourceTemplate } from '@ossy/cms-client-react'
import {
  Alert,
  InputTitle,
  Overlay,
  Button,
  Layout,
  useInputValue,
} from 'design-system'
import { EditFields } from './fieldFactory.js'

export const CreateDocumentPage = () => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const [searchParams] = useSearchParams()
  const templateId = searchParams.get('templateId')
  const location = searchParams.get('location')
  const template = useResourceTemplate(templateId)
  const [documentData, setDocumentData] = useState({})
  const { createDocument } = useResources()
  const [documentName, setDocumentName] = useInputValue()
  const [error, setError] = useState()

  const onCancel = () => {
    setDocumentData({})
    const search = createSearchParams({ location })
    navigate(`/${workspaceId}/resources?${search}`)
  }

  const onFinish = () => {
    setDocumentData({})
    const search = createSearchParams({ location })
    navigate(`/${workspaceId}/resources?${search}`)
  }

  const onCreateDocument = () => {
    if (documentName === '' || documentName === undefined) {
      setError('Document name needs to be set')
      return ''
    }

    createDocument({
      type: templateId,
      location: location,
      name: documentName,
      content: documentData
    })
      .then(() => onFinish())
      .catch(error => { setError(error.message) })
  }

  const updateFieldData = event => {

    let value

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }

    const path = event.target.type === 'radio'
      ? [event.target.name]
      : event.target.id.split('.')

    setDocumentData(set(lensPath(path), value, documentData))
  }

  return (
    <>
      <InputTitle
        id="document-name"
        type="text"
        className="d-block"
        placeholder="Untitled document"
        value={documentName}
        onChange={setDocumentName}
      />
      <EditFields data={documentData} onChange={updateFieldData} templateFields={template?.fields || []}/>
      { error && <Alert>{error}</Alert>}
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--space-l)'}}>
        <Button variant="link" onClick={onCancel}>Cancel</Button>
        <Button variant="cta" onClick={onCreateDocument}>Create {template?.name}</Button>
      </div>
    </>
  )
}
