import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUsers, AsyncStatus } from '@ossy/cms-client-react'
import { Title, Table, Button } from 'design-system'

export const Users = () => {
    const { users = [], status } = useUsers()
    const navigate = useNavigate()
  
    if (status === AsyncStatus.Loading) return <>Loading</>

    const header = [{ key: 'id', label: 'User id' }, { key: 'email', label: 'Email' }]
  
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--space-m)', padding: 'var(--space-s)' }}>
          <Title>Users</Title>
          <Button variant="cta" onClick={() => navigate('../invite')} prefix="user-add">
            Invite user
          </Button>
        </div>
        <Table header={header} data={users}/>
      </>
    )
  }