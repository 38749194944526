import { useState, useEffect } from 'react'
import { useResource, useResourceTemplate } from '@ossy/cms-client-react'
import {
  Switch,
  Stack,
  Button,
  Text,
  Title,
  Tag,
  InputTitle,
  useInputValue,
  Icon2,
  Image
} from 'design-system'
import { VisualContentDescriptors } from './jobs/VisualContentDescriptors.jsx'

const ViewMode = {
  Read: 'READ',
  Edit: 'EDIT'
}

export const ImageResource = ({
  resourceId,
  onClose: _onClose
}) => {
  const [viewMode, setViewMode] = useState(ViewMode.Read)
  const [error] = useState()
  const [resourceContent, setResourceContent] = useState()
  const [resourceName, setResourceName] = useInputValue('')

  const {
    resource,
    removeResource,
    updateResourceContent,
    renameResource
  } = useResource(resourceId)

  const template = useResourceTemplate(resource.type)

  const onCloseResource = () => {
    if (!_onClose) return
    setViewMode(ViewMode.Read)
    setResourceName('')
    setResourceContent({})
    _onClose()
  }

  const onUpdateResource = () => {
    updateResourceContent(resourceContent)
      .then(() => setViewMode(ViewMode.Read))
      // TODO: proper error handling
      .catch(() => alert('Something went wrong!'))
      // .catch(error => {
      //   if (error.type === 'PROPERTIES_REQUIRED') setError('You need to change at least one field in the document to publish an update')
      // })
  }

  const onRenameResource = () => {
    if (resourceName === resource.name) return
    // TODO: loading/error handling
    renameResource(resourceName)
  }

  const onRemoveResource = () => {
    removeResource()
      .then(onCloseResource)
  }

  useEffect(() => {
    setResourceContent(resource.content)
    setResourceName(resource.name)
  }, [resource])

  return (
    <Stack bordered>
      <Stack.Item fill>
        <Stack bordered>

          <Stack.Item>
            <Stack horizontal style={{ height: '48px', alignItems: 'center', gap: '4px' }}>

              <Stack.Item fill>
                <InputTitle
                  id="document-name"
                  type="text"
                  style={{ display: 'block', width: '100%', padding: '16px 8px' }}
                  value={resourceName}
                  onChange={setResourceName}
                  onBlur={onRenameResource}
                />
              </Stack.Item>

              <Switch on={viewMode}>

                <Switch.Case match={[ViewMode.Read]}>

                  {/* <Button variant="link" onClick={() => setViewMode(ViewMode.Edit)}>
                    Edit
                  </Button> */}

                  <Button prefix="trash-empty"  variant="command-danger" onClick={onRemoveResource}/>

                </Switch.Case>

                <Switch.Case match={[ViewMode.Edit]}>
                  <Button prefix="close" variant="command" onClick={() => setViewMode(ViewMode.Read)} />
                  {
                    !!template?.fields
                      ? <Button prefix="check" variant="command" onClick={onUpdateResource}/>
                      : <Button disabled prefix="check" variant="disabled"/>
                  }

                </Switch.Case>

              </Switch>

              {
                !!_onClose && (  <Button prefix="close" variant="command" onClick={onCloseResource} /> )
              }

            </Stack>

          </Stack.Item>

          {
            !!error && (
              <Stack.Item>
                {error}
              </Stack.Item>
            )
          }

          <Stack.Item>
            <Text variant="small" style={{ padding: '8px', color: 'hsl(0, 0%, 40%)', display: 'flex', gap: '4px', flexDirection: 'row', alignItems: 'center' }}>
              <Icon2 name="file"/> Type: {resource.type}; Id: {resource.id} <Icon2 name="time"/> Last updated: {new Date(resource.lastUpdated).toLocaleString()}; 
            </Text>
          </Stack.Item>

          <Stack.Item fill style={{ padding: '16px 8px' }}>

            <Switch on={viewMode}>

              <Switch.Case match={[ViewMode.Read]}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Image
                    src={resource?.content?.sizes?.galleryLarge || resource.content.src}
                    placeholderSrc={resource?.content?.sizes?.['loader-square-blurred-after'] || resource.content.src}
                    style={{ width: 'auto', height: '400px', margin: 'var(--space-l) auto' }}
                  />
                </div>
              </Switch.Case>

              {/* <Switch.Case match={[ViewMode.Edit]}>
                {
                  !!template.fields
                    ? <EditFields data={resourceContent} onChange={onUpdateResourceContentField} templateFields={template.fields} />
                    : <div className="inset-m"><Title variant="secondary">This documents template has been deleted and can therefore not be edited</Title></div>
                }
              </Switch.Case> */}

            </Switch>
          </Stack.Item>

          <Stack.Item bordered>
            <VisualContentDescriptors resourceId={resource.id} />
          </Stack.Item>

        </Stack>
      </Stack.Item>
    </Stack>
  )
}
