import React from 'react'
import { Guide, Layout } from 'design-system'

export const sections = [
  {
    id: 'not-found',
    surface: 'primary',
    maxWidth: "s",
    style: { padding: 'var(--space-xl) var(--space-m)' },
    render: ({ notFound }) => (
      <Layout variant="off-center" style={{ height: '100dvh' }}>
        <Guide slot="content" {...notFound} />
      </Layout>
    )
  }
]
