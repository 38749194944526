import React from 'react'
import { css } from 'glamor'

const shadowM = '3px 0 10px hsla(0, 0%, 85%, .75)'
const styles = {
  borderBottom: '5px solid hsl(0, 89%, 43%)',
  backgroundColor: 'hsl(0, 0%, 97%)',
  padding: '16px',
  borderRadius: '8px',
  fontFamily: 'sans-serif',
  boxShadow: shadowM
}

export const Alert = ({
  children,
  style = {},
  as: Element = 'div',
  ...props
}) =>
  <Element {...props} style={{ ...styles, ...style }}>
    {children}
  </Element>
