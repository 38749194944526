import { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { AuthenticationStatus, useAuthentication } from '@ossy/cms-client-react'
import { Switch, Layout, Guide, Icon } from 'design-system'

export const AuthenticationGuard = ({ children }) => {
  const { status } = useAuthentication()

  return (
    <Switch on={status}>

      <Switch.Case match={[AuthenticationStatus.NotAuthenticated]}>
        <Layout variant="off-center" style={{ height: '100%' }}>
          <Guide
            style={{ width: '100vw', maxWidth: '300px' }}
            slot="content"
            title="Not Authenticated"
            text="You need to login to view this page"
          />
        </Layout>
      </Switch.Case>

      <Switch.Case match={[AuthenticationStatus.AuthenticationError]}>
        <Layout variant="off-center" style={{ height: '100%' }}>
          <Guide
            style={{ width: '100vw', maxWidth: '300px' }}
            slot="content"
            title="Error"
            text="An error occurred while trying to authenticate, try again in a few minutes"
          />
        </Layout>
      </Switch.Case>

      <Switch.Case match={[AuthenticationStatus.Verifying, AuthenticationStatus.NoInitialized]}>
        <Layout variant="off-center" style={{ height: '100%' }}>
          <Icon
            name="Cached"
            style={{ width: '64px', height: '64px' }}
            className="rotate fill-50"
            slot="content"
          />
        </Layout>
      </Switch.Case>

      <Switch.Case match={[AuthenticationStatus.Authenticated]}>
        {children}
      </Switch.Case>

    </Switch>
  )
}
