export const reactHooks = {
  navigation: `
- [useAuthentication](#useAuthentication)
- [useQuery](#useQuery)
- [useResource](#useResource)
- [useResources](#useResources)
- [useResourceTemplate](#useResourceTemplate)
- [useWorkspace](#useWorkspace)
- [useWorkspaces](#useWorkspaces)
- [WorkspaceProvider](#WorkspaceProvider)
  `,
  content: `
# Using react hooks

To make it easy to use our cms we have created a library
with react hooks you can use to interact with the REST API.
To use these hooks install the package **@ossy/cms-client-react**

\`\`\`
npm install @ossy/cms-client-react
\`\`\`

Then wrap our app in a <WorkspaceProvider /> that will
handle data storage and configuration for us.
You will need the workspaceId for the workspace you want to fetch data from.
The workspaceId can be found in the list of workspaces you have access to or
in the url when you view the resources in the UI.

\`\`\`
// App.jsx
import { WorkspaceProvider, useResources } from '@ossy/cms-client-react'

const MyComponent = () => {
  const { status, resources } = useResources('/folder/path/in/cms')

  if (status === 'Error') return <>Something went wrong</>
  if (status === 'Loading') return <>Loading...</>
  if (status === 'Success') return (
    <>
      { resources.map(resource => <div key={resource.id}>{resource.name}</div>)) }
    </>
  )
}

export const App = () => (
  <WorkspaceProvider workspaceId="your-workspace-id">
    <MyComponent />
  </WorkspaceProvider>
)
\`\`\`

## useAuthentication
\`\`\`
const { status, user, signIn, signOff } = useAuthentication()
\`\`\`

## useQuery
Load a list of resources that match specific criteria
\`\`\`
const { status, resources } = useQuery({ type: 'image/png' })
\`\`\`

## useResources
Load a list of resources in a specific directory
\`\`\`
const { status, resources } = useResources('/')
\`\`\`

**Parameters**

- **path** - Folder path in the cms UI

**Returns**

Returns an object containing

- **status** - String value that indicates loading status.
  Can be used to show loading indicators or error screens.
  Possible values are: NotInitialized, Loading, Success, Error
- **resources** - Array of resources, defaults to an empty array when
  loading is not Success

## useResource

\`\`\`
const { status, resource } = useResource('resourceId')
\`\`\`

**Parameters**

- **resourceId** - Id of the resource you want to fetch

**Returns**

Returns an object containing

- **status** - String value that indicates loading status.
  Can be used to show loading indicators or error screens.
  Possible values are: NotInitialized, Loading, Success, Error
- **resource** - The fetched resource, defaults to an empty object
  when status is not Success

## useResourceTemplate
\`\`\`
const template = useResourceTemplate('blog-post')
\`\`\`

## useWorkspace
\`\`\`
const { status, workspace, inviteUser } = useWorkspace()
\`\`\`

## useWorkspaces
\`\`\`
const { status, workspaces, createWorkspace } = useWorkspaces()
\`\`\`

## WorkspaceProvider
\`\`\`
const { status, user, signIn, signOff } = useAuthentication({ type: 'image/png' })
\`\`\`
  `
}
