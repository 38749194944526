import React from 'react';
import { css } from 'glamor'
import { Title, View, Text, Separator } from 'design-system';
import { useCompany } from './DataLoader.jsx';

const grid = css({
    width: '100%',
    height: '100vh',
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(6, minmax(390px, 425px))',
    gridTemplateRows: '1fr 1fr',
    gap: '24px',
    background: '#f1f1f1',
})

const card = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    background: '#fff',
    padding: '32px',
    boxSizing: 'border-box',
})

export const Overview = () => {
    const company = useCompany()
    
    return (
        <div {...grid}>

            <div data-surface="primary"  {...card}>
                <Title variant="tertiary">Fiscal years</Title>
                <Separator  />
                <View gap="s" style={{ flexGrow: '1' }}>

                    {company.getFiscalYears().map(fiscalYear => (
                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {fiscalYear.fiscalYear}:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {fiscalYear.start} - {fiscalYear.end}
                            </Text>
                        </View>
                    ))}

                </View>
            </div>

            <div data-surface="primary"  {...card}>
                <Title variant="tertiary">{company.name}</Title>
                <Separator  />
                <View gap="s" style={{ flexGrow: '1' }}>

                    <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            Registered:
                        </Text>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            {company.registrationDate}
                        </Text>
                    </View>

                    <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                          Financial year: 
                        </Text>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            {company.financialYearStart} - {company.financialYearEnd}
                        </Text>
                    </View>


                    <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            VAT Number:
                        </Text>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            {company.vatNumber}
                        </Text>
                    </View>

                    <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            VAT Registred from:
                        </Text>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                         {company.vatRegistredFrom}
                        </Text>
                    </View>


                    <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            VAT Payment responsibility from: 
                        </Text>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                            {company.vatPaymentResponsibilityFrom}
                        </Text>
                    </View>

                    <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                          VAT Payment period:
                        </Text>
                        <Text variant="m" style={{ marginBottom: 0 }} >
                          {company.vatPaymentPeriod}
                        </Text>
                    </View>

                </View>

            </div>

        </div>
    )
}