import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkspaces } from '@ossy/cms-client-react'
import {
  Button,
  useInputValue,
  Layout,
  Title,
  Input
} from 'design-system'
import { AuthenticationGuard } from '../authentication'
import { useDocumentTitle } from 'design-system'

export const CreateWorkspacePage = () => {
  const navigate = useNavigate()
  const [error, setError] = useState()
  const [workspaceName, setWorkspaceName] = useInputValue()
  const { createWorkspace } = useWorkspaces()

  useDocumentTitle('Create workspace')

  const onCreateWorkspace = workspaceName => {
    createWorkspace(workspaceName)
      .then(workspace => { navigate(`/${workspace.id}/resources`) })
      .catch(err => { setError(err.message) })
  }

  return (
    <AuthenticationGuard>
      <Title variant="primary" className="stack-m">Create a new workspace</Title>

      <Input
        id="workspaceName"
        className={ error ? 'stack-s' : 'stack-m' }
        type="text"
        placeholder="Workspace name"
        required
        onChange={setWorkspaceName}
        style={{ width: '100%' }}
      />

      <div className={error ? 'stack-m' : ''}>{ error }</div>

      <div className="d-flex justify-end">

        <Button
          variant="link"
          id="cancel"
          onClick={() => navigate(-1)}>Cancel
        </Button>

        <Button
          id="createWorkspace"
          variant="cta"
          onClick={() => onCreateWorkspace(workspaceName)}>Create
        </Button>

      </div>
    </AuthenticationGuard>
  )
}
