import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApiTokens } from '@ossy/cms-client-react'
import {
  useInputValue,
  Button,
  Title,
  Input,
  Switch,
  Guide,
  Text
} from 'design-system'
import { useDocumentTitle } from 'design-system'

const FlowStage= {
  Input: 'Input',
  Success: 'Success',
  Error: 'Error'
}

export const CreateApiToken = () => {
  const navigate = useNavigate()
  const { createApiToken } = useApiTokens()
  const [flowStage, setFlowStage] = useState(FlowStage.Input)
  const [description, setDescription] = useInputValue()
  const [token, setToken] = useState()
  const formRef = useRef()

  useDocumentTitle('Create API Token')

  const onCreateApiToken = event => {
    event.preventDefault()
    formRef.current.reportValidity() && createApiToken(description)
      .then(token => setToken(token.token))
      .then(token => setFlowStage(FlowStage.Success))
      .catch(() => setFlowStage(FlowStage.Error))
  }

  return (
    <Switch on={flowStage}>

      <Switch.Case match={[FlowStage.Input]}>

        <Title variant="primary" style={{ marginBottom: 'var(--space-m)' }}>
          Create API Token
        </Title>

        <Text style={{ marginBottom: 'var(--space-m)' }}>
          An API token is needed to interact with our API
        </Text>
        <form
          ref={formRef}
          onSubmit={onCreateApiToken}
          style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Input
            id="description"
            type="text"
            placeholder="Description"
            required
            onChange={setDescription}
          />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }} >

            <Button
              variant="link"
              id="cancelButton"
              onClick={() => navigate(-1)}
            >Cancel
            </Button>

            <Button
              prefix="math-plus"
              variant="cta"
              id="registerButton"
              type="submit"
              onClick={onCreateApiToken}
            >Create
            </Button>

          </div>
        </form>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Success]}>
        <Guide
          title="Your token"
          titleVariant="primary"
        >
          <Text style={{ marginBottom: 'var(--space-m)'}}>
            Here is your token. It will not be shown again so copy it now.
          </Text>
          <Input readonly value={token} style={{ width: '100%'}} />
        </Guide>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Error]}>
        <Text>
          Something went wrong, try again in a couple of minutes
        </Text>
      </Switch.Case>

    </Switch>
  )
}
