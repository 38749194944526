import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthentication } from '@ossy/cms-client-react'
import { Title, Switch, Layout } from 'design-system'
import { useDocumentTitle } from 'design-system'

const FlowStage = {
  SigningIn: 'SigningIn',
  Success: 'Success',
  Error: 'Error'
}

export const VerifySignIn = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { verifySignIn } = useAuthentication()
  const [flowStage, setFlowStage] = useState(FlowStage.SigningIn)

  useDocumentTitle('Signing in...')

  useEffect(() => {
    if (!token) return

    let didCancel = false

    setFlowStage(FlowStage.SigningIn)

    verifySignIn(token)
      .then(() => {
        if (didCancel) return
        setFlowStage(FlowStage.Success)
      })
      .then(() => navigate('/profile/workspaces'))
      .catch(() => {
        if (didCancel) return
        setFlowStage(FlowStage.Error)
      })

    return () => {
      didCancel = true
    }

  }, [token])

  return (
    <Switch on={flowStage}>

      <Switch.Case match={[FlowStage.SigningIn]}>
        <Title variant="primary">Signing in...</Title>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Success]}>
        <Title variant="primary">Success, redirecting...</Title>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Error]}>
        <Title variant="primary">Could not sign in, try again later</Title>
      </Switch.Case>

    </Switch>
  )
}
