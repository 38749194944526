import { useNavigate } from 'react-router-dom'
import { useWorkspaces, AsyncStatus } from '@ossy/cms-client-react'
import { Title, Text, Switch, Button, Layout, Guide, Icon2, View, Stack } from 'design-system'
import { useDocumentTitle } from 'design-system'
import { AuthenticationGuard } from '../authentication'

const PageFlow = {
  Error: AsyncStatus.Error,
  Loading: AsyncStatus.Loading,
  Success: AsyncStatus.Success,
  NoWorkspacesCreated: 'NoWorkspacesCreated'
}

export const SelectWorkspacePage = () => {
  const navigate = useNavigate()
  const { status: asyncStatus, workspaces } = useWorkspaces()

  const pageFlow = (asyncStatus === AsyncStatus.Success && workspaces.length === 0)
    ? PageFlow.NoWorkspacesCreated
    : asyncStatus

  useDocumentTitle('Select workspace')

  const navigateToWorkspace = id => {
    navigate(`/${id}/resources`)
  }

  return (
    <AuthenticationGuard>

      <Switch on={pageFlow}>
        <Switch.Case match={[PageFlow.Success]}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 'var(--space-m)',
          }}
          >
            <Title variant="primary">
              My workspaces
            </Title>
            <Button
              prefix="math-plus"
              variant="cta"
              href="/profile/create-workspace"
              className="mobile:d-none">
                New workspace
            </Button>
          </div>
        </Switch.Case>
      </Switch>

      <Switch on={pageFlow}>

        <Switch.Case match={[PageFlow.Error]}>
          <Layout variant="off-center" style={{ height: '100%' }}>
            <Guide
              slot="content"
              style={{ width: '100vw', maxWidth: '500px' }}
              title="We could not load your workspaces"
              text={`
                  Something went wrong when loading your workspaces.
                  Maybe your session have expired, or we messed up somehow.
                  Try starting over from our home page.
              `}
              actions={[{
                variant: 'cta',
                href: '/',
                label: 'Go to out home page'
              }]}
            />
          </Layout>
        </Switch.Case>

        <Switch.Case match={[PageFlow.Loading]}>
          <Icon2
            slot="content"
            name="spinner"
            style={{ width: '64px', height: '64px' }}
            className="rotate"
          />
          <Text>Loading...</Text>
        </Switch.Case>

        <Switch.Case match={[PageFlow.Success]}>
          <Stack bordered style={{ overflowY: 'auto' }}>
            {workspaces.map(workspace => (
                <Stack.Item
                  key={workspace.id}
                  layout="row"
                  style={{ alignItems: 'center'}}
                  gap="m"
                  inset="m"
                  selectable
                  onClick={() => navigateToWorkspace(workspace.id)}
                >
                  <Text style={{ fontWeight: 'bold' }}>{workspace.name}</Text>
                  <Text variant="small" as="span">{workspace.id}</Text>
                  <div style={{ flexGrow: '1' }} />
                  <Button prefix="user-add" variant="command" />
                </Stack.Item>
              ))}
          </Stack>
        </Switch.Case>

        <Switch.Case match={[PageFlow.NoWorkspacesCreated]}>
          <Layout variant="vertical-top" style={{ flexGrow: '1' }}>
            <Guide
              slot="content"
              style={{ padding: 'var(--space-xl) var(--space-m)', width: '100vw', maxWidth: '500px' }}
              title="🎉 Let's get the party started 🎉"
              text={`
                To get started we need to create a workspace.
                A workspace is an encapsulation of resources, resource templates,
                billing information, and what users have access to these things.
              `}
              actions={[{
                variant: 'cta',
                label: 'Create a workspace',
                href: '/create-workspace'
              }]}
            />
          </Layout>
        </Switch.Case>

      </Switch>
    </AuthenticationGuard>
  )
}
