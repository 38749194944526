import * as moment from 'moment'
import { Tax } from './tax-client'
import { CalendarClient } from '../calendar'

export class ForecastClient {

    static of(company) {
        return new ForecastClient(company)
    }

    constructor(company) {
      this.company = company
    }

    getFiscalYear(fiscalYearInNumber) {

        const days = CalendarClient.getAllDaysInDateSpan(
          this.company.currentFiscalYearStart,
          this.company.currentFiscalYearEnd
        )

        const timelineProjections = projectTimeline({
          startDate: this.company.currentFiscalYearStart,
          endDate: this.company.currentFiscalYearEnd,
          employees: this.company.employees,
          contracts: this.company.contracts,
          vacations: this.company.vacations,
        })
       
        const daysFormated = days.map(day => ({
            date: day.format('YYYY-MM-DD'),
            isWorkday: CalendarClient.isWorkDay(day),
            month: day.format('MMMM'),
            monthDay: day.format('DD'),
            year: day.format('YYYY'),
            monthNumber: day.format('MM'),
            isoWeekday: day.isoWeekday(),
        }))
        .reduce((acc, curr) => {
            const month = curr.month
            const monthDays = acc[month] || []
            monthDays.push(curr)
            acc[month] = monthDays
            return acc
        }, {})
        // convert to array with key for month name and key for days
    
        return Object.entries(daysFormated).reduce((acc, [month, days]) => {
            const day = days[0]
            const monthNumber = day.monthNumber
            const isCurrentMonth = moment().format('MM') === monthNumber
            const incomeEvents = timelineProjections.filter(event => event.date.format('MM') === monthNumber)
            const projectedIncome = incomeEvents.reduce((acc, curr) => acc + curr.income, 0)
            const hoursWorked = incomeEvents.reduce((acc, curr) => acc + curr.hoursWorked, 0)
            const monthlyCostOfEmployees = incomeEvents[0].monthlyCostOfEmployees
            const revenue = projectedIncome - monthlyCostOfEmployees

            return [
                ...acc,
                {
                    isCurrentMonth,
                    month: month,
                    year: day.year,
                    monthNumber: monthNumber,
                    days: days,
                    projectedIncome: projectedIncome,
                    monthlyCostOfEmployees: monthlyCostOfEmployees,
                    revenue: revenue,
                    hoursWorked: hoursWorked,
                }
            
            ]
        }, [])
        .sort((a, b) => moment(a.days[0].date).isBefore(moment(b.days[0].date)) ? -1 : 1)
    }
}


// We need to take into account that the first payment is due 30days after the month it has been worked in
function projectTimeline({ startDate, endDate, employees = [], contracts = [], vacations = [] }) {
    const allDays = CalendarClient.getAllDaysInDateSpan(startDate, endDate)
  
    return allDays.flatMap(day => {
      const date = moment(day)
      let income = 0
  
      // the employee list might contains employees that haven't started yet or quit
      // so we need to filter out the ones that are active on this day
      const activeEmployees = employees.filter(employee => {
        if (!employee.startDate) return false
        const endDate = employee.endDate || moment('2099-01-04')
        const isEmployed = day.isBetween(employee.startDate, endDate, 'day', '[]')
        return isEmployed
      })
  
      const monthlySalaryOfEmployees = activeEmployees.reduce((sum, employee) => sum + Number(employee.monthlySalary), 0)
      const montlhySocialTaxForEmployees = monthlySalaryOfEmployees * Tax.socialTax
      const monthlyCostOfEmployees = montlhySocialTaxForEmployees + monthlySalaryOfEmployees
  
      const workingEmployees = activeEmployees.filter(employee => {
        const isOnVacation = vacations
          .filter(vacation => vacation.employeeId === employee.id)
          .some(({ start, end }) => day.isBetween(start, end, 'day', '[]'))
        return !isOnVacation
      })
  
      if (!CalendarClient.isWorkDay(day) || !workingEmployees.length) {
        return { date, income: 0, monthlyCostOfEmployees, hoursWorked: 0 }
      }
  
     return workingEmployees.map(employee => {
        // need to find the contract that is active on this day
        const contract = contracts.find(contract => contract.id === employee.contractId)
        
        if (!contract) {
          return { date, income: 0, monthlyCostOfEmployees, hoursWorked: 0 }
        }

        const hasContractStarted = day.isSameOrAfter(contract.startDate, 'day')
        const hasContractEnded = contract.endDate ? day.isAfter(contract.endDate, 'day') : false
        const isContractActive = hasContractStarted && !hasContractEnded

        if (!isContractActive) {
          return { date, income: 0, monthlyCostOfEmployees, hoursWorked: 0 }
        }
  
        const income = Number(contract.hourlyCompensation) * employee.workHoursPerDay
        return { date, income: income, monthlyCostOfEmployees, hoursWorked: Number(employee.workHoursPerDay) || 0 }
  
      })
  
  
  
    })
  
  }