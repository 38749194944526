import { useWorkspace } from '@ossy/cms-client-react'
import { Button, View } from 'design-system'
import { useActivePath } from '../resources'

export const SidebarPrimary = ({}) => {
    const activePath = useActivePath()
    const { workspace } = useWorkspace()

    return (
        <View surface="primary" gap="m" style={{ width: 'var(--size, 64px)', height: '100%', alignItems: 'center' }}>

            <View style={{
              borderRadius: '0',
              borderBottom: '1px solid var(--separator-primary)',
              padding: '8px',
              height: 'var(--size, 64px)',
              width: 'var(--size, 64px)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <Button
                variant="cta"
                prefix="math-plus"
                style={{ padding: '8px' }}
                href={`@resources/create?location=${activePath}`}
              />
            </View>

            <View style={{ flexGrow: '.5' }} />

            {/* <Button variant="command" prefix="home-alt" /> */}
            {/* <Button variant="command" prefix="menu-boxed" /> */}
            <Button variant="command" prefix="options" href="@workspace/settings" />
            <Button variant="command" prefix="user-list" href="@workspace/users" />

            <Button prefix="readme" variant="command" href="/documentation/guides" target="_blank">
              {/* Guides */}
            </Button>

            <Button prefix="file-document" variant="command" href="/documentation/references" target="_blank">
              {/* References */}
            </Button>
            

            <View style={{ flexGrow: '1' }} />

            <Button variant="command" prefix="support" />
            <Button variant="command" prefix="push-chevron-right" />

        </View>
    )
}