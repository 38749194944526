import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkspace } from '@ossy/cms-client-react'
import {
  useInputValue,
  Button,
  Title,
  Input,
  Switch,
  Guide,
  Text,
  View
} from 'design-system'
import { useDocumentTitle } from 'design-system'

const FlowStage= {
  EnterEmail: 'EnterEmail',
  Success: 'Success',
  Error: 'Error'
}

export const Invite = () => {
  const navigate = useNavigate()
  const { inviteUser } = useWorkspace()
  const [flowStage, setFlowStage] = useState(FlowStage.EnterEmail)
  const [email, setEmail] = useInputValue()
  const formRef = useRef()

  useDocumentTitle('Invite user')

  const sendInvite = event => {
    event.preventDefault()
    formRef.current.reportValidity() && inviteUser(email)
      .then(() => setFlowStage(FlowStage.Success))
      .catch(() => setFlowStage(FlowStage.Error))
  }

  return (
    <Switch on={flowStage}>

      <Switch.Case match={[FlowStage.EnterEmail]}>
        <View gap="m">
          <Title variant="primary" >Invite user</Title>
          <Text>
              Invite a user to manage and contribute content to this workspace
          </Text>
          <form
            ref={formRef}
            onSubmit={sendInvite}
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Input
              id="registerField"
              className="stack-m"
              type="email"
              placeholder="Email address"
              required
              onChange={setEmail}
            />

            <div className="d-flex justify-end">

              <Button
                variant="link"
                id="cancelButton"
                onClick={() => navigate(-1)}>Cancel
              </Button>

              <Button
                variant="cta"
                id="registerButton"
                type="submit"
                onClick={sendInvite}>Send invite
              </Button>
            </div>
          </form>
        </View>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Success]}>
        <Guide
          title="Success"
          titleVariant="primary"
        >
          <Text>
            We sent them an invitation over email.
            When they have accepted they will show upp in the list of users.
            <br/>
            ✌️
          </Text>
        </Guide>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Error]}>
        <Text>
          Something went wrong, try again in a couple of minutes
        </Text>
      </Switch.Case>

    </Switch>
  )
}
