
import React, { useMemo } from 'react'
import { css } from 'glamor'
import { Icon2 } from '../icons/Icon2.jsx'

export const Button = ({
  type,
  as = 'button',
  prefix,
  suffix,
  variant,
  href,
  label,
  children,
  onClick: _onClick = () => {},
  ...props
}) => {
  const Element = !!href ? 'a' : as

  const onClick = (e) => {
    if (href && ['svg', 'path'].includes(e.target.tagName)) {
      e.preventDefault()
      e.target.setAttribute('data-href', href)
    }

    _onClick(e)
  }

  const buttonInternalStyles = useMemo(() => {
    return css({
      boxSizing: 'border-box',
      cursor: 'pointer',
      lineHeight: '1',
      outline: 'none',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      textDecoration: `var(--button-${variant}-text-decoration, var(--button-default-text-decoration, none))`,
      gap: `var(--button-${variant}-gap, var(--button-default-gap, 8px))`,
      fontFamily: `var(--button-${variant}-font-family, var(--button-default-font-family, sans-serif))`,
      borderRadius: `var(--button-${variant}-border-radius, var(--button-default-border-radius))`,
      fontSize: `var(--button-${variant}-font-size, var(--button-default-font-size, 16px))`,
      padding: `var(--button-${variant}-padding, var(--button-default-padding, 8px 24px))`,
      fontWeight: `var(--button-${variant}-font-weight, var(--button-default-font-weight, bold))`,
      letterSpacing: `var(--button-${variant}-letter-spacing, var(--button-default-letter-spacing))`,
      borderWidth: `var(--button-${variant}-border-width, var(--button-default-border-width))`,
      borderStyle: `var(--button-${variant}-border-style, var(--button-default-border-style))`,
      borderColor: `var(--button-${variant}-border-color, var(--button-default-border-color))`,
      color: `var(--button-${variant}-color, var(--foreground, var(--button-default-color)))`,
      fill: `var(--button-${variant}-color, var(--button-default-color))`,
      background: `var(--button-${variant}-background, var(--button-default-background))`,
      boxShadow: `var(--button-${variant}-box-shadow, var(--button-default-box-shadow, none))`,
      transition: `var(--button-${variant}-transition, var(--button-default-transition, background-color .3s, color .3s, fill .3s, border-default-color .3s))`,
      textTransform: `var(--button-${variant}-text-transform, vat(--button-default-text-transform))`,
      ':hover': {
        textDecoration: `var(--button-${variant}-text-decoration-hover, var(--button-default-text-decoration-hover, var(--button-default-text-decoration)))`,
        background: `var(--button-${variant}-background-hover, var(--button-default-background-hover))`,
        color: `var(--button-${variant}-color-hover, var(--button-default-color-hover))`,
        fill: `var(--button-${variant}-color-hover, var(--button-default-color-hover))`,
        borderColor: `var(--button-${variant}-border-color-hover, var(--button-default-border-color-hover, var(--button-${variant}-border-color, var(--button-default-border-color))))`,
        boxShadow: `var(--button-${variant}-box-shadow-hover, var(--button-${variant}-box-shadow, var(--button-default-box-shadow, none)))`,
      },
      ':focus': {
        borderColor: `var(--button-${variant}-border-color-focus, var(--button-default-border-color-focus, hsl(199, 98%, 43%)))`,
      },
      ':disabled': {
        background: `var(--button-${variant}-background-disabled, var(--button-default-background-disabled))`,
        color: `var(--button-${variant}-color-disabled, var(--button-default-color-disabled))`,
        fill: `var(--button-${variant}-color-disabled, var(--button-default-color-disabled))`,
        border: `var(--button-${variant}-border-disabled, var(--button-default-border-disabled), var(--button-default-border))`,
        boxShadow: `var(--button-${variant}-box-shadow, var(--button-default-box-shadow, none))`,
        cursor: 'not-allowed'
      },
    })
  }, [variant])

  return (
    <Element
      type={type || 'button'}
      href={href}
      onClick={onClick}
      {...props}
      {...buttonInternalStyles}
    >
      {prefix && typeof prefix === 'string' ? <Icon2 name={prefix} size="s" /> : <Icon2 size="s" {...prefix} /> }
      {label || children}
      {suffix && typeof suffix === 'string' ? <Icon2 name={suffix} size="s" /> : <Icon2 size="s" {...suffix} /> }
    </Element>
  )
}
