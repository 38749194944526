import React from 'react';
import { css } from 'glamor'
import { Title, View, Text } from 'design-system';
import { useCalendarMonths } from './DataLoader.jsx';

const grid = css({
    width: '100%',
    height: '100vh',
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(6, minmax(390px, 425px))',
    gridTemplateRows: '1fr 1fr',
    gap: '1px',
    background: '#f1f1f1',
})

const calendarGrid = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridAutoRows: 'auto',
    gap: '8px',
    padding: '24px',
    justifyItems: 'center',
    alignItems: 'center',
})

const calendarDay = css({
    boxSizing: 'border-box',
    padding: '10px',
    // width: '40px',
    // height: '40px',
    fontSize: '.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    padding: '8px',

    '&:hover': {
        backgroundColor: '#ddd'
    },

    '[data-day-off="true"]': {
        backgroundColor: '#f8efef'
    }
})

const monthCard = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    background: '#fff',
    padding: '8px',
    boxSizing: 'border-box',
})

export const Timesheet = () => {
    const months = useCalendarMonths()
    return (
        <div {...grid}>

            {months.map(month => (
                <div data-surface="primary" {...monthCard}>
                    <Title variant="tertiary">{month.month}</Title>
                    <View gap="s" style={{ flexGrow: '1' }}>
                        Tasks
                    </View>

                    <div {...calendarGrid}>
                        {month.days.map(day => (
                            <Text
                                variant="small"
                                {...calendarDay}
                                data-day-off={!day.isWorkday}
                                style={{ margin: 0}}
                            >
                                8
                            </Text>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}