import React from 'react'
import { useApiTokens } from '@ossy/cms-client-react'
import { Title, Text, Button, Stack } from 'design-system'

export const ApiTokens = () => {
    const { tokens } = useApiTokens()
  
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--space-m)'}}>
          <Title>API Tokens</Title>
          <Button prefix="math-plus" variant="cta" href="/profile/create-api-token" >
            New token
          </Button>
        </div>

        <Stack bordered style={{ overflowY: 'auto' }}>
            {tokens.map(token => (
                <Stack.Item
                  key={token.id}
                  layout="row"
                  style={{ alignItems: 'center'}}
                  gap="m"
                  inset="m"
                  selectable
                >
                  <Text style={{ fontWeight: 'bold' }}>{token.description}</Text>
                  <div style={{ flexGrow: '1' }} />
                  <Button prefix="trash" variant="command-danger" />
                </Stack.Item>
              ))}
          </Stack>
  
      </>
    )
  }