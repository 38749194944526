import { useState, useEffect } from 'react'
import { pipe, keys, map, set, lensPath } from 'ramda'
import { useResource, useResourceTemplate } from '@ossy/cms-client-react'
import {
  Switch,
  Stack,
  Button,
  Title,
  Text,
  InputTitle,
  useInputValue,
  View
} from 'design-system'
import { EditFields } from './fieldFactory.js'

const ViewMode = {
  Read: 'READ',
  Edit: 'EDIT'
}

export const DocumentResource = ({
  resourceId,
  onClose: _onClose
}) => {
  const [viewMode, setViewMode] = useState(ViewMode.Read)
  const [error] = useState()
  const [resourceContent, setResourceContent] = useState()
  const [resourceName, setResourceName] = useInputValue('')

  const {
    resource,
    removeResource,
    updateResourceContent,
    renameResource
  } = useResource(resourceId)

  const template = useResourceTemplate(resource.type)

  const onCloseResource = () => {
    if (!_onClose) return
    setViewMode(ViewMode.Read)
    setResourceName('')
    setResourceContent({})
    _onClose()
  }

  const onUpdateResource = () => {
    updateResourceContent(resourceContent)
      .then(() => setViewMode(ViewMode.Read))
      // TODO: proper error handling
      .catch(() => alert('Something went wrong!'))
      // .catch(error => {
      //   if (error.type === 'PROPERTIES_REQUIRED') setError('You need to change at least one field in the document to publish an update')
      // })
  }

  const onUpdateResourceContentField = () => {
    let value
    // TODO test all the different field types
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }

    const path = event.target.type === 'radio'
      ? [event.target.name]
      : event.target.id.split('.')

    const lens = lensPath(path)

    setResourceContent(prevContent => set(lens, value, prevContent))
  }

  const onRenameResource = () => {
    if (resourceName === resource.name) return
    // TODO: loading/error handling
    renameResource(resourceName)
  }

  const onRemoveResource = () => {
    removeResource()
      .then(onCloseResource)
  }

  useEffect(() => {
    setResourceContent(resource.content)
    setResourceName(resource.name)
  }, [resource])

  return (
    <Stack bordered>
      <Stack.Item fill>
        <Stack bordered>

          <Stack.Item>
            <Stack horizontal style={{ height: '48px', alignItems: 'center', gap: '4px' }}>

              <Stack.Item fill style={{  }}>
                <InputTitle
                  id="document-name"
                  type="text"
                  style={{ display: 'block', width: '100%', padding: '16px 8px' }}
                  value={resourceName}
                  onChange={setResourceName}
                  onBlur={onRenameResource}
                />
              </Stack.Item>

              <Switch on={viewMode}>

                <Switch.Case match={[ViewMode.Read]}>
                  <Button prefix="pen" variant="command" onClick={() => setViewMode(ViewMode.Edit)}/>
                  <Button prefix="trash-empty" variant="command-danger" onClick={onRemoveResource}/>
                </Switch.Case>

                <Switch.Case match={[ViewMode.Edit]}>
                  <Button prefix="close" variant="command" onClick={() => setViewMode(ViewMode.Read)}/>
                  {
                    !!template.fields
                      ? <Button prefix="check" variant="command" onClick={onUpdateResource}/>
                      : <Button prefix="check" disabled variant="disabled"/>
                  }
                </Switch.Case>

              </Switch>

              {
                !!_onClose && <Button prefix="close" variant="command" onClick={onCloseResource} />
              }

            </Stack>

          </Stack.Item>

          {
            !!error && (
              <Stack.Item>
                {error}
              </Stack.Item>
            )
          }

          <Stack.Item fill style={{ padding: '16px 8px' }}>

            <Switch on={viewMode}>

              <Switch.Case match={[ViewMode.Read]}>
                <View gap="m">
                  {
                    !!resourceContent && (template.fields || [])
                      .map(field => ({ name: field.name, value: resourceContent[field.name] }))
                      .map(field => (
                        <View gap="s" key={field.name}>
                          <Text style={{ fontWight: 'bold' }} >{field.name}</Text>
                          {
                            typeof field.value === 'object'
                              ? pipe(keys, map(key => field.value[key] && <div>{key}</div>))(field.value)
                              : <span className="d-block" style={{ lineHeight: '1.6', maxWidth: '500px' }}>{field.value}</span>
                          }
                        </View>
                      ))
                  }
                </View>
              </Switch.Case>

              <Switch.Case match={[ViewMode.Edit]}>
                {
                  !!template.fields
                    ? <EditFields data={resourceContent} onChange={onUpdateResourceContentField} templateFields={template.fields} />
                    : <View inset="m" ><Title variant="secondary">This documents template has been deleted and can therefore not be edited</Title></View>
                }
              </Switch.Case>

            </Switch>
          </Stack.Item>

          <Stack.Item>
            <Text variant="small" style={{ padding: '8px', color: 'hsl(0, 0%, 40%)' }}>
              Created: {new Date(resource.created).toLocaleString()}; Template name: {template.name}; Resource ID: {resource.id}
            </Text>
          </Stack.Item>

        </Stack>
      </Stack.Item>
    </Stack>
  )
}
