import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '@ossy/cms-client-react'
import {
  useInputValue,
  Button,
  Title,
  Input2,
  Switch,
  Guide,
  View,
  Text
} from 'design-system'
import { useDocumentTitle } from 'design-system'

const FlowStage= {
  EnterEmail: 'EnterEmail',
  Success: 'Success',
  Error: 'Error'
}

export const SignUp = () => {
  const navigate = useNavigate()
  const { signUp } = useAuthentication()
  const [flowStage, setFlowStage] = useState(FlowStage.EnterEmail)
  const [email, setEmail] = useInputValue()
  const formRef = useRef()

  useDocumentTitle('Sign up')

  const createAccount = event => {
    event.preventDefault()
    formRef.current.reportValidity() && signUp(email)
      .then(() => setFlowStage(FlowStage.Success))
      .catch(() => setFlowStage(FlowStage.Error))
  }

  return (
    <Switch on={flowStage}>

      <Switch.Case match={[FlowStage.EnterEmail]}>
        <View gap="s" style={{ height: '100%' }}>
          <Title variant="primary" style={{ marginBottom: 'var(--space-m)' }}>Sign up</Title>
          <Text style={{ marginBottom: 'var(--space-m)' }}>
            Enter your email address and we will send you a magic login link.
          </Text>
          <form
            ref={formRef}
            onSubmit={createAccount}
            style={{ width: '100%', flexGrow: '1', display: 'flex', flexDirection: 'column', gap: 'var(--space-m)', justifyContent: 'space-between' }}
          >
            <Input2
              id="registerField"
              type="email"
              placeholder="Email address"
              required
              onChange={setEmail}
            />

            <View layout="row" style={{ gap: 'var(--space-s)', justifyContent: 'flex-end' }} >

              <Button
                variant="link"
                id="cancelButton"
                onClick={() => navigate(-1)}>Cancel
              </Button>

              <Button
                id="registerButton"
                variant="cta"
                prefix='user-add'
                onClick={createAccount}>Sign up
              </Button>

            </View>
          </form>
        </View>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Success]}>
        <Guide
          title="Success"
          titleVariant="primary"
        >
          <Text>
            We don't use passwords, we are cool like that.
            But you do need to verify your email so go check your inbox.
            Don't forget to check your spam folder.
            Now let's hope our email service is working.
            <br/>
            😎🤞
          </Text>
        </Guide>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Error]}>
        Something went wrong - try again in a couple of minutes
      </Switch.Case>

    </Switch>
  )
}
