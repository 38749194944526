export const uploadResourceTemplates = {
  navigation: `
- [Getting your API token](#getting-your-api-token)
- [Defining resoure templates](#defining-resource-templates)
- [Upload resource templates](#upload-resource-templates)
  `,
  content: `
# Uploading resource templates

This guide will walk you through how to upload resource templates.

## Getting your api token

## Defining resource templates

## Upload resource templates

`
}
