import React from 'react'
import {
  Title,
  Text,
  PageSection,
  Tabs,
  Image,
  Card,
  Guide
} from 'design-system'
import { MarkdownViewer } from 'design-system-extras'

export const sections = [

  {
    id: "hero",
    surface: "decorated",
    style: { padding: 'var(--space-xxl) var(--space-m)' },
    render: ({ hero }) => <Guide {...hero } align="center"/>
  },

  {
    id: "features",
    surface: "primary",
    style: { padding: 'var(--space-xxl) var(--space-m)' },
    render: ({ features }) => (
      <>
        <Title variant="primary" style={{ textAlign: 'center', marginBottom: 'var(--space-l)'}}>
          {features.title}
        </Title>

        <div style={{
          padding: 'var(--space-m)',
          background: 'hsl(190, 89%, 93%)',
          border: '1px solid hsl(190, 89%, 43%)',
          borderRadius: 'var(--space-s)',
          marginBottom: 'var(--space-xl)'
        }}
        >
          <Text>
            This service is somewhere between a proof of concept and early alpha.
            We use it for your own websites but there are some bugs and features aren't
            fully developed yet. Some downtime is also to be expected.
          </Text>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 'var(--space-xl)'}}>

          <div>
            <Title variant="tertiary" style={{ marginBottom: 'var(--space-s)' }}>
              Custom fields
            </Title>
            <Text style={{ maxWidth: '400px' }}>
              You decide the shape of the content. What fields are shown in the UI
              are decided with simple custom fields definitions in JSON.
              These can live in your Git repository and be integrated into an CD/CD
              pipeline
            </Text>
          </div>

          <div>
            <Title variant="tertiary" style={{ marginBottom: 'var(--space-s)' }}>
              React hooks
            </Title>
            <Text style={{ maxWidth: '400px' }}>
              Displaying content on your React website is now easier than ever with
              our React hooks integration. Just give the useResources hook the folder
              path and it will load the data for you.
            </Text>
          </div>

          <div>
            <Title variant="tertiary" style={{ marginBottom: 'var(--space-s)' }}>
              Simple web UI
            </Title>
            <Text style={{ maxWidth: '400px' }} >
              With an simple and intuative UI, updating content is super easy.
              The content is organised in a familiar directory structure.
              You can also invite collaborators and manage users.
            </Text>
          </div>

          <div>
            <Title variant="tertiary" style={{ marginBottom: 'var(--space-s)' }}>
              REST API
            </Title>
            <Text style={{ maxWidth: '400px' }}>
              If you aren't using React we also have a REST API you can use.
              All requests and responses are in JSON. All you need is an API token.
            </Text>
          </div>

        </div>
      </>
    )
  },

  {
    id: "simple-web-ui",
    surface: "secondary",
    style: { padding: 'var(--space-xxl) var(--space-m)' },
    render: ({ simpleWebUi }) => (
      <>
        <Title
          variant="primary"
          style={{ textAlign: 'center', marginBottom: 'var(--space-l)'}}
        >
          {simpleWebUi.title}
        </Title>

        <Text style={{ textAlign: 'center', marginBottom: 'var(--space-xl)' }}>
          {simpleWebUi.text}
        </Text>

        <Card style={{ padding: '0' }}>
          <Image src={simpleWebUi.imgSrc} />
        </Card>
      </>
      )

    },

    {
      id: "display-content",
      surface: "primary",
      style: { padding: 'var(--space-xxl) var(--space-m)' },
      render: ({ displayContent }) => (
        <>
          <Title variant="primary" style={{ textAlign: 'center', marginBottom: 'var(--space-l)'}}>
            {displayContent.title}
          </Title>

          <Text style={{ textAlign: 'center', marginBottom: 'var(--space-xl)' }}>
            {displayContent.text}
          </Text>

          <div style={{ gap: 'var(--space-xl)'}}>
            <MarkdownViewer children={displayContent.hooksExample} />
          </div>
        </>
      )
    }
]
