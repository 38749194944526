import { useWorkspace } from '@ossy/cms-client-react'
import { View, Title, Button } from 'design-system'
import { useActivePath } from '../resources'

export const CreateResourcePage = ({}) => {
    const activePath = useActivePath()
    const { workspace } = useWorkspace()

    const groupsByTemplaceCategory = (workspace.resourceTemplates || [])
      .filter(({ id }) => id.startsWith('@ossy/'))
      .reduce((categories, template) => {
        const categoryName = template.categoryName || template.id.split('/')[1]
        const category = categories.get(categoryName) || { categoryName, actions: [] }
        category.actions.push({
          label: template.name,
          prefix: template.icon || 'add',
          href: `/${workspace.id}/resources/create/document?location=${activePath}&templateId=${template.id}`
        })
        categories.set(categoryName, category)
        return categories
      }, new Map())

    const categories =  [
      {
        categoryName: 'Media',
        actions: [
          { 
            label: 'Directory',
            prefix: 'folder-add',
            href: `/${workspace.id}/resources/create/directory?location=${activePath}`
          },
          {
            label: 'Upload',
            prefix: 'software-upload',
            href: `/${workspace.id}/resources/upload?location=${activePath}`
          },
        ]
      },
      ...Array.from(groupsByTemplaceCategory.values())
    ]

    return (
        <View surface="primary" layout="vertical-top-m" gap="m" style={{ height: '100%', alignItems: 'center' }}>
              
          <View
            layout="row-wrap"
            inset="s"
            surface="primary"
            roundness="m"
            gap="l"
            slot="content"
            style={{
              flexDirection: 'column',
              overflowY: 'auto',
              height: '100%',
            }}>

            { categories.map(category => (
              <View gap="m">

                <Title variant="tertiary">{category.categoryName}</Title>

                <View layout="row-wrap" gap="m">
                  {category.actions.map((action) => <Button {...action} key={action.label} />)}
                </View>

              </View>
              ))}

          </View>
      </View>
    )
}