import React from 'react';
import { css } from 'glamor'
import { Title, View, Text } from 'design-system';
import { useContracts } from './DataLoader.jsx';

const grid = css({
    width: '100%',
    height: '100%',
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(6, minmax(390px, 425px))',
    gridTemplateRows: '1fr 1fr',
    gap: '24px',
    background: '#f1f1f1',
})

const card = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    background: '#fff',
    padding: '32px',
    boxSizing: 'border-box',
})

export const Contracts = () => {
    const contracts = useContracts()
    
    return (
        <div {...grid}>

            { contracts.map(contract => (

                <div data-surface="primary" {...card}>
                    <Title variant="tertiary">{contract.clientName}</Title>
                    <View gap="s" style={{ flexGrow: '1' }}>
                        <Text style={{ marginBottom: 0 }} >
                            ID: {contract.id}
                        </Text>

                        <Text style={{ marginBottom: 0 }}>
                            Start date {contract.startDate}
                        </Text>

                        <Text style={{ marginBottom: 0 }}>
                            End date: {contract.endDate || 'ongoing'}
                        </Text>

                        <Text style={{ marginBottom: 0 }}>
                            Notice period: {contract.noticePeriodInDays} days
                        </Text>

                        <Text style={{ marginBottom: 0 }}>
                            Compensation: {contract.hourlyCompensation} kr/h
                        </Text>

                    </View>

                </div>
            ))}

        </div>
    )
}