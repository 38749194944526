import { useParams, Outlet } from 'react-router-dom'
import { WorkspaceProvider, useSdk } from '@ossy/cms-client-react'
import { SDK } from '@ossy/sdk'
import { WorkspaceDataLoader } from './WorkspaceDataLoader.js'
import { WorkspaceLayout } from './WorkspaceLayout.js'

export const WorkspaceModule = ({ content, children }) => {
  const { workspaceId } = useParams()
  const appSdk = useSdk()
  const workspaceSdk = SDK.of({ ...appSdk.config, workspaceId })

  return (
    <WorkspaceProvider sdk={workspaceSdk}>
      <WorkspaceDataLoader>
        <WorkspaceLayout>
          { content ?? children ?? <Outlet /> }
        </WorkspaceLayout>
      </WorkspaceDataLoader>
    </WorkspaceProvider>

  )
}
