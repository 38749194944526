import { useSearchParams } from 'react-router-dom'
import {
    unless,
    startsWith,
    o,
    endsWith
  } from 'ramda'

const prependSlash = unless(startsWith('/'), path => `/${path}`)
const appendSlash = unless(endsWith('/'), path => `${path}/`)
const addSlashes = o(prependSlash, appendSlash)

export const useActivePath = () => {
    const [searchParams] = useSearchParams()
    const activePath = addSlashes(searchParams.get('location') || '/')
    return activePath
  }