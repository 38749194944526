import React from 'react'
import { redirect } from 'react-router-dom'
import { Page, View } from 'design-system'
import { CardLayout, CardSidebar } from 'design-system-extras'
import { GeneralSettings } from './GeneralSettings.jsx'
import { Documentation, DocumentationLayout } from './documentation'
import { PublicPagesLayout } from './PublicPages.layout.jsx'
import { SelectWorkspacePage, CreateWorkspacePage, WorkspaceModule, Users } from './workspaces'
import { Login, SignUp, VerifySignIn, VerifyInvitation, Invite, CreateApiToken, ApiTokens } from './authentication'
import { ResourcesPage, CreateDirectory, CreateDocumentPage, UploadImage, CreateResourcePage } from './resources'
import { Swedish as SE } from './data.js'
import { sections as LandingPage } from './LandingPage.jsx'
import { sections as NotFound } from './NotFound.jsx'
import { Logo } from './Logo.jsx'
import { DataLoader } from './planner/DataLoader.jsx'
import { Overview } from './planner/Overview.jsx'
import { Tasks } from './planner/Tasks.jsx'
import { Timesheet } from './planner/Timesheet.jsx'
import { Forecast } from './planner/forecast'
import { Contracts } from './planner/Contracts.jsx'
import { Employees } from './planner/Employees.jsx'

import './app.css'

export default [

  {
    id: 'documentation-concepts',
    path: '/documentation/guides/concepts',
    element: <PublicPagesLayout content={(
      <DocumentationLayout
        surface="base"
        cardSurface="primary"
        content={<Documentation about="concepts" />}
        navigation={[
          { id: 'concepts-nav', label: 'Concepts', href: '@documentation-concepts' },
        ]}
      />
    )} />
  },

  {
    id: 'documentation-resource-templates',
    path: '/documentation/guides/upload-resource-templates',
    element: <PublicPagesLayout content={(
      <DocumentationLayout
        surface="base"
        cardSurface="primary"
        content={<Documentation about="uploadResourceTemplate" />}
        navigation={[
          { id: 'concepts-nav', label: 'Concepts', href: '@documentation-concepts' },
        ]}
      />
    )}/>
  },

  {
    id: 'documentation-react-hooks',
    path: '/documentation/references/react-hooks',
    element: <PublicPagesLayout content={(
      <DocumentationLayout
        surface="base"
        cardSurface="primary"
        content={<Documentation about="reactHooks" />}
        navigation={[
          { id: 'react-hooks-nav', label: 'React hooks', href: '@documentation-react-hooks' },
          { id: 'rest-api-nav', label: 'REST API', href: '@documentation-rest-api' },
        ]}
      />
    )}/>
  },

  {
    id: 'documentation-rest-api',
    path: '/documentation/references/rest-api',
    element: <PublicPagesLayout content={(
      <DocumentationLayout
        surface="base"
        cardSurface="primary"
        content={<Documentation about="restApi" />}
        navigation={[
          { id: 'react-hooks-nav', label: 'React hooks', href: '@documentation-react-hooks' },
          { id: 'rest-api-nav', label: 'REST API', href: '@documentation-rest-api' },
        ]}
      />
    )}/>
  },

  {
    id: 'workspace',
    path: '/:workspaceId',
    loader: ({ params }) => redirect(`/${params.workspaceId}/resources`)
  },

  {
    id: 'resources',
    path: '/:workspaceId/resources',
    element: <WorkspaceModule content={<ResourcesPage />} />
  },

  {
    id: 'resources/create',
    path: '/:workspaceId/resources/create',
    element: <WorkspaceModule content={<CreateResourcePage />} />
  },

  {
    id: 'upload-media',
    path: "/:workspaceId/resources/upload",
    element: <WorkspaceModule content={<CardLayout layout="off-center-s"  surface="base" cardSurface="primary" content={<UploadImage/>} /> } />,
  },

  {
    id: 'create-directory',
    path: "/:workspaceId/resources/create/directory",
    element: <WorkspaceModule content={<CardLayout layout="off-center-s" surface="base" cardSurface="primary" content={<CreateDirectory/>} /> } />,
  },

  {
    id: 'create-document',
    path: "/:workspaceId/resources/create/document",
    element: <WorkspaceModule content={<CardLayout layout="off-center-m"  surface="base" cardSurface="primary" content={<CreateDocumentPage/>} /> } />,
  },

  {
    id: 'planner',
    path: '/:workspaceId/planner',
    loader: () => redirect('planner/overview')
  },

  {
    id: 'planner/overview',
    path: '/:workspaceId/planner/overview',
    element:  <WorkspaceModule content={<DataLoader content={<Overview />} />} />,
  },

  {
    id: 'planner/tasks',
    path: '/:workspaceId/planner/tasks',
    element:  <WorkspaceModule content={<DataLoader content={<Tasks />} />} />
  },

  {
    id: 'planner/timesheet',
    path: '/:workspaceId/planner/timesheet',
    element:  <WorkspaceModule content={<DataLoader content={<Timesheet />} />} />
  },

  {
    id: 'planner/contracts',
    path: '/:workspaceId/planner/contracts',
    element:  <WorkspaceModule content={<DataLoader content={<Contracts />} />} />
  },

  {
    id: 'planner/employees',
    path: '/:workspaceId/planner/employees',
    element:  <WorkspaceModule content={<DataLoader content={<Employees />} />} />
  },

  {
    id: 'planner/forecast',
    path: '/:workspaceId/planner/forecast',
    element:  <WorkspaceModule content={<DataLoader content={<Forecast />} />} />,
  },

  {
    id: 'workspace/settings',
    path: '/:workspaceId/settings',
    element: <WorkspaceModule content={(
      <CardSidebar
        surface="base"
        cardSurface="primary"
        maxWidth="l"
        content={<GeneralSettings/>}
        style={{ height: '100vh' }}
        navigation={[
          {  prefix: 'menu-boxed', label: 'General', href: '@workspace/settings' },
          {  prefix: 'user-list', label: 'Users', href: '@workspace/users' }
        ]}
      />
      )} />
  },

  {
    id: 'workspace/users',
    path: '/:workspaceId/users',
    element: <WorkspaceModule content={(
      <CardSidebar
        surface="base"
        cardSurface="primary"
        maxWidth="l"
        content={<Users />}
        style={{ height: '100vh' }}
        navigation={[
          {  prefix: 'menu-boxed', label: 'General', href: '@workspace/settings' },
          {  prefix: 'user-list', label: 'Users', href: '@workspace/users' }
        ]}
      />
      )} />
  },

  {
    id: 'workspace-invite',
    path: '/:workspaceId/invite',
    element: <WorkspaceModule content={(
      <CardSidebar
        surface="base"
        cardSurface="primary"
        maxWidth="l"
        content={<Invite />}
        style={{ height: '100vh' }}
        navigation={[
          {  prefix: 'menu-boxed', label: 'General', href: '@workspace/settings' },
          {  prefix: 'user-list', label: 'Users', href: '@workspace/users' }
        ]}
      />
      )} />
  },

  {
    id: 'home',
    path: '/',
    element: <PublicPagesLayout content={<Page maxWidth="m" sections={LandingPage} { ...SE.LandingPage }/>} />,
  },

  {
    id: 'sign-up',
    path: '/sign-up',
    element: <CardLayout
      surface="base"
      layout='off-center-s'
      cardVariant="default"
      cardSurface="primary"
      header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
      content={<SignUp/>}
    />
  },

  {
    id: 'sign-in',
    path: '/sign-in',
    element: <CardLayout
      surface="base"
      layout='off-center-s'
      cardVariant="default"
      cardSurface="primary"
      header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
      content={<Login/>}
    />
  },

  {
    id: 'verify-sign-in',
    path: '/verify-sign-in',
    element: <CardLayout
      surface="base"
      layout='off-center-s'
      cardVariant="default"
      cardSurface="primary"
      header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
      content={<VerifySignIn/>}
    />
  },

  {
    id: 'invitations/verify',
    path: '/invitations/verify',
    element: <CardLayout
      surface="base"
      layout='off-center-s'
      cardVariant="default"
      cardSurface="primary"
      header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
      content={<VerifyInvitation/>}
    />,
  },

  {
    id: 'profile/workspaces',
    path: '/profile/workspaces',
    element: (
      <CardSidebar
        surface="base"
        cardSurface="primary"
        maxWidth="l"
        header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
        content={<SelectWorkspacePage />}
        style={{ height: '100vh' }}
        navigation={[
          { id: 'workspaces-nav', prefix: 'controller', label: 'Workspaces', href: '@profile/workspaces' },
          { id: 'api-tokens-nav', prefix: 'keyhole', label: 'Api tokens', href: '@profile/api-token/create' }
        ]}
      />
    ),
  },

  {
    id: 'profile/create-workspace',
    path: '/profile/create-workspace',
    element: (
      <CardSidebar
        surface="base"
        cardSurface="primary"
        maxWidth="l"
        header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
        content={<CreateWorkspacePage/>}
        style={{ height: '100vh' }}
        navigation={[
          { id: 'workspaces-nav', prefix: 'controller', label: 'Workspaces', href: '@profile/workspaces' },
          { id: 'api-tokens-nav', prefix: 'keyhole', label: 'Api tokens', href: '@profile/api-token/create' }
        ]}
      />
    )
  },

  {
    id: 'profile/api-tokens',
    path: '/profile/api-tokens',
    element: (
      <CardSidebar
        surface="base"
        cardSurface="primary"
        maxWidth="l"
        header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
        content={<ApiTokens/>}
        style={{ height: '100vh' }}
        navigation={[
          { id: 'workspaces-nav', prefix: 'controller', label: 'Workspaces', href: '@profile/workspaces' },
          { id: 'api-tokens-nav', prefix: 'keyhole', label: 'Api tokens', href: '@profile/api-token/create' }
        ]}
      />
    ),
  },

  {
    id: 'profile/api-token/create',
    path: '/profile/api-token/create',
    element: (
      <CardSidebar
        surface="base"
        cardSurface="primary"
        maxWidth="l"
        header={<View slot="header" layout="row" style={{ justifyContent: 'center', padding: 'var(--space-m)' }}><Logo/></View>}
        content={<CreateApiToken/>}
        style={{ height: '100vh' }}
        navigation={[
          { id: 'workspaces-nav', prefix: 'controller', label: 'Workspaces', href: '@profile/workspaces' },
          { id: 'api-tokens-nav', prefix: 'keyhole', label: 'Api tokens', href: '@profile/api-token/create' }
        ]}
      />
    )
  },

  {
    id: 'profile',
    path: '/profile',
    loader: () => redirect('/profile/workspaces'),
  },

  {
    id: 'documentation',
    path: '/documentation',
    loader: () => redirect('/documentation/guides/concepts'),
  },

  {
    id: 'documentation/guides',
    path: '/documentation/guides',
    loader: () => redirect('/documentation/guides/concepts'),
  },

  {
    id: 'documentation/references',
    path: '/documentation/references',
    loader: () => redirect('/documentation/references/react-hooks'),
  },

  {
    id: 'not-found',
    path: "/404",
    element: <Page style={{ height: '100dvh' }} sections={NotFound} {...SE.NotFound} />,
  },

  {
    id: 'catch-all',
    path: '*',
    loader: () => redirect('/404')
  }
]