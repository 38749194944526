import React from 'react'
import { useWorkspace } from '@ossy/cms-client-react'
import { Title, Text, Guide, Layout } from 'design-system'

export const GeneralSettings = () => {
  const { workspace } = useWorkspace()
  return (
    <div style={{ height: '100%', padding: 'var(--space-l)' }}>
      <Title variant="tertiary" style={{ marginBottom: 'var(--space-m)' }}>
        Workspace name
      </Title>
      <Text variant="tertiary" style={{ marginBottom: 'var(--space-l)' }}>
        {workspace.name}
      </Text>
      <Title variant="tertiary" style={{ marginBottom: 'var(--space-m)' }}>
        Workspace id
      </Title>
      <Text variant="tertiary" style={{ marginBottom: 'var(--space-l)' }}>
        {workspace.id}
      </Text>
    </div>
  )
}
