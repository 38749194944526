export const Swedish = {

  LandingPage: {
    hero: {
      title: 'Headless CMS with easy React integration',
      titleVariant: 'display',
      actions: [{ label: 'Try it out for free!', variant: 'cta', href: '/sign-up' }],
      text: `
        Managing content should be easy.
        That's why we spent way too much time and
        effort on building our own CMS solution.
        It's not even in alpha, it's full off bugs, and it will be down from time to time.
        But come on, live a little and suffer with us!
        Try it it out.
      `
    },

    features: {
      title: 'Features'
    },

    simpleWebUi: {
      title: 'Update content easily through the UI',
      text: `
        With an simple and intuative UI, updating content is super easy.
        The content is organised in a familiar directory structure.
        You can also invite collaborators and manage users.
      `,
      imgSrc: 'https://d1yuixo7x29bj4.cloudfront.net/36zDqF0TKZZ5KkJdyg7NH/KgqxjIFZpuVl-VMydMDdC.png'
    },

    displayContent: {
      title: 'Display content on your websites through React hooks',
      text: `
        Displaying content on your React website is now easier than ever with
        our React hooks integration. Just give the useResources hook the folder
        path and it will load the data for you.
      `,
      hooksExample: `
        \`\`\`
        // App.jsx
        import { WorkspaceProvider, useResources } from '@ossy/cms-client-react'

        export const App = () => (
          <WorkspaceProvider workspaceId="your-workspace-id">
            <MyComponent />
          </WorkspaceProvider>
        )

        const MyComponent = () => {
          const { status, resources } = useResources('/folder/path/in/cms')

          return status !== 'Success'
            ? <>Loading...</>
            : <>{resources.map(resource => <div key={resource.id}>{resource.name}</div>)}</>
        }

        \`\`\`
      `
    }

  },

  NotFound: {
    notFound: {
      title: 'Not Found',
      text: 'We could not find the page you are looking for',
      actions: [{
        variant: 'cta',
        label: 'Go to start page',
        href: '/'
      }]
    }
  }
}
