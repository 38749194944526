import React, { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";
import { Switch, View, Guide } from 'design-system'
import { CompanyClient } from "./company";
import months from './data.json'
import { ForecastClient } from "./forecast";
import { useResources, AsyncStatus } from "@ossy/cms-client-react";

const DataContext = createContext({ months });

export const useData = () => useContext(DataContext);
export const useCalendarMonths = () => useContext(DataContext)?.months || [];
export const useCompany = () => useContext(DataContext)?.company;
export const useContracts = () => useCompany()?.contracts || [];
export const useEmployees = () => useCompany()?.employees || [];
export const useForecast = () => {
    const company = useCompany()
    if (!company) return []
    return ForecastClient.of(company).getFiscalYear()
}

export const DataLoader = ({ children, content }) => {
    const { status, resources } = useResources('/@ossy/consultancy/')

    if (!resources?.length && status === AsyncStatus.Success) {
        return (
            <View surface="base" layout="off-center" style={{ height: '100%' }}>
                <Guide
                    title="Looks empty"
                    text="Create something to get started"
                    actions={[{ label: 'Create', variant: 'cta', prefix: 'math-plus' }]}
                />
            </View>
        )
    }

    const Company = CompanyClient.of({
        basicInfo: resources?.find(x => x.type === '@ossy/consultancy/companyBasicInfo')?.content,
        contracts: resources?.filter(x => x.type === '@ossy/consultancy/contract')?.map(x => x.content),
        employees: resources?.filter(x => x.type === '@ossy/consultancy/employee')?.map(x => ({...x.content, id: x.id})),
    })

    const data = {
        months,
        company: Company
    }

    return (
        <View surface="base" style={{ height: '100%' }}>
            <DataContext.Provider value={data}>
                <Switch on={status}>

                    <Switch.Case match={[AsyncStatus.Loading, AsyncStatus.NotInitialized]}>
                        Loading...
                    </Switch.Case>

                    <Switch.Case match={[AsyncStatus.Error]}>
                        Error
                    </Switch.Case>

                    <Switch.Case match={[AsyncStatus.Success]}>
                        { content ?? children ?? <Outlet />}
                    </Switch.Case>

                </Switch>
            </DataContext.Provider>
        </View>
    )
}