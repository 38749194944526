import React from 'react'
import { Switch, Icon2, Layout, Guide, View, Text, DelayedRender  } from 'design-system'
import { useWorkspace, AsyncStatus} from '@ossy/cms-client-react'

export const WorkspaceDataLoader = ({ children }) => {
  const { status } = useWorkspace()

  return (
    <Switch on={status}>

      <Switch.Case match={[AsyncStatus.Error]}>
        <Layout variant="off-center" style={{ height: '100dvh', background: 'var(--surface-linear-gradient)' }}>
          <Guide
            slot="content"
            style={{ width: '100vw', maxWidth: '500px' }}
            title="We could not load your workspace"
            text={`
                Something went wrong when loading your workspace.
                Maybe your session have expired, or we messed up somehow.
                Try starting over from our home page.
            `}
            actions={[{
              variant: 'cta',
              href: '/',
              label: 'Go to our home page'
            }]}
          />
        </Layout>
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Loading, AsyncStatus.NotInitialized]}>
        <DelayedRender>
          <Layout
            variant="off-center"
            style={{ height: '100dvh', background: 'var(--surface-linear-gradient)' }}
          >
            <View slot="content" style={{ alignItems: 'center' }}>
              <Icon2
                name="spinner"
                size="m"
                className="rotate"
              />
              <Text>
                Loading your workspace
              </Text>
            </View>
          </Layout>
        </DelayedRender>
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Success]}>
        {children}
      </Switch.Case>

    </Switch>
  )
}
