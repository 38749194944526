import React, { useState, useRef } from 'react'
import {
  useInputValue,
  Button,
  Switch,
  Title,
  Input2,
  Guide,
  Text,
  View
} from 'design-system'
import { useDocumentTitle } from 'design-system'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '@ossy/cms-client-react'

const FlowStage = {
  Input: 'Input',
  SigningIn: 'SigningIn',
  Success: 'Success'
}

export const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useInputValue()
  const formRef = useRef()
  const { signIn } = useAuthentication()
  const [flowStage, setFlowStage] = useState(FlowStage.Input)

  useDocumentTitle('Sign in')

  const login = event => {
    event.preventDefault()
    setFlowStage(FlowStage.SigningIn)
    formRef.current.reportValidity() && signIn(email)
      .then(() => setFlowStage(FlowStage.Success))
      .catch(() => setFlowStage(FlowStage.Success)) // Don't show the user if an email doesn't exist
  }

  return (
    <Switch on={flowStage}>

      <Switch.Case match={[FlowStage.Input]}>
        <View gap="s" style={{ height: '100%' }}>
          <Title variant="primary" style={{ marginBottom: 'var(--space-m)' }}>Sign in</Title>
          <Text style={{ marginBottom: 'var(--space-m)' }}>
            Enter your email address and we will send you a magic login link.
          </Text>
          <form
            ref={formRef}
            onSubmit={login}
            style={{ width: '100%', flexGrow: '1', display: 'flex', flexDirection: 'column', gap: 'var(--space-m)', justifyContent: 'space-between' }}
          >
            <Input2
              id="loginemailField"
              type="email"
              required
              placeholder="Email address"
              onChange={setEmail}
            />

            <View layout="row" style={{ gap: 'var(--space-s)', justifyContent: 'flex-end' }} >

              <Button
                variant="link"
                id="cancelButton"
                onClick={() => navigate(-1)}>Cancel
              </Button>

              <Button
                id="registerButton"
                variant="cta"
                prefix='log-in'
                onClick={login}>Sign in
              </Button>

            </View>
          </form>
        </View>
      </Switch.Case>

      <Switch.Case match={[FlowStage.SigningIn]}>
        <Title variant="primary">Signing in...</Title>
      </Switch.Case>

      <Switch.Case match={[FlowStage.Success]}>
        <Guide title="Success" titleVariant="primary" >
          <Text>
            We don't use passwords, we are cool like that.
            If the account exist, we sent a magic login link.
            Don't forget to check your spam folder.
            Now let's hope our email service is working.
            <br/>
            😎🤞
          </Text>
        </Guide>
      </Switch.Case>

    </Switch>
  )
}
