import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useResources, useWorkspace, AsyncStatus } from '@ossy/cms-client-react'
import {
  pipe,
  split,
  filter,
  identity,
  last,
} from 'ramda'
import {
  Switch,
  Stack,
  DropZone,
  Breadcrumbs,
  Guide,
  DelayedRender,
  Icon2,
  Layout,
  useDocumentTitle,
  Image,
  View,
  Text,
  Button
} from 'design-system'
import { ResourceFactory } from './ResourceFactory.js'
import { css } from 'glamor'
import { useActivePath } from './useActivePath.js'

const hideScrollbar = css({
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'nonde'
  }
})

const Path = ({ path, ...props }) => (
  <Button variant="command" {...props}>
    {last(path.split('/'))}
  </Button>
)

const ResourcesListStatus = {
  Error: 'Error',
  Loading: 'Loading',
  Empty: 'Empty',
  Success: 'Success'
}

export const ResourcesPage = () => {
  const [_, setSearchParams] = useSearchParams()
  const { workspace } = useWorkspace()
  const [selectedResourceId, setSelectedResourceId] = useState()
  const [resourcesListStatus, setResourcesListStatus] = useState(ResourcesListStatus.Loading)
  const activePath = useActivePath()

  const resourceTemplates = (workspace.resourceTemplates || [])
    .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {})

  const {
    status: resourcesStatus,
    resources,
    removeResource,
    moveResource
  } = useResources(activePath)

  useDocumentTitle(workspace.name || 'loading workspace')

  const goTo = x => () => { setSearchParams({ location: `${x}/` }) }
  const goToRoot = () => { setSearchParams({}) }

  const onMoveResource = newLocation => resourceId => {
    moveResource(resourceId, newLocation)
  }

  const getActivePath = () => pipe(
    split('/'),
    filter(identity),
    x => x.reduce((acc, curr, i) => i === 0
      ? [`/${curr}`]
      : [...acc, `${acc[i - 1]}/${curr}`], []),
    filter(identity),
    paths => paths.map((path, i) => {
      const isLast = i === (paths.length - 1)
      if (isLast) return <Path key={path} path={path} onClick={goTo(path)} />
      return (
        <DropZone onDrop={onMoveResource(`${path}/`)}>
          <Path key={path} path={path} onClick={goTo(path)} />
        </DropZone>
      )
    })
  )(activePath)

  useEffect(() => {
    if (resourcesStatus === AsyncStatus.NotInitialized) {
      setResourcesListStatus(ResourcesListStatus.Loading)
    } else if (resourcesStatus === AsyncStatus.Loading) {
      setResourcesListStatus(ResourcesListStatus.Loading)
    } else if (resourcesStatus === AsyncStatus.Error) {
      setResourcesListStatus(ResourcesListStatus.Error)
    } else if (resourcesStatus === AsyncStatus.Success) {
      resources.length === 0
        ? setResourcesListStatus(ResourcesListStatus.Empty)
        : setResourcesListStatus(ResourcesListStatus.Success)
    }
  }, [resources, resourcesStatus])

  return (
    <div data-surface="primary" style={{ height: '100%' }}>

      <Stack horizontal bordered style={{ height: '100%' }}>

        <Stack.Item fill>
          <Stack bordered style={{ height: '100%' }}>

            <Stack.Item
              data-surface="primary"
              layout="row"
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 'var(--space-s) var(--space-m)',
                gap: 'var(--space-m)',
                height: '48px',
                flexShrink: 0
              }}
            >

              <Breadcrumbs>
                {
                  activePath === '/'
                    ? <Button prefix="home-alt" variant="command" onClick={goToRoot}/>
                    : (
                      <DropZone onDrop={onMoveResource('/')}>
                        <Button prefix="home-alt" variant="command" onClick={goToRoot}/>
                      </DropZone>
                    )
                }

                { activePath !== '/' && getActivePath() }
              </Breadcrumbs>

              <Button variant="command" prefix="push-chevron-down" />
            </Stack.Item>

            <Stack.Item fill {...hideScrollbar} style={{ overflowY: 'auto'  }}>
              <Switch on={resourcesListStatus}>

                <Switch.Case match={[ResourcesListStatus.Error]}>
                  <Layout variant="off-center" style={{ height: '100%' }}>
                    <Guide
                      slot="content"
                      title="Something went wrong"
                      text="We could not fetch the data to display"
                      style={{ width: '100vw', maxWidth: '350px' }}
                    />
                  </Layout>
                </Switch.Case>

                <Switch.Case match={[ResourcesListStatus.Loading]}>
                  <DelayedRender>
                    {[1, 2, 3].map(n => (
                      <Stack.Item key={n}>
                        <div
                          className="inset-m cursor-pointer sans-serif d-flex align-center justify-between"
                        >
                          <div className="d-flex align-center">
                            <span
                              className="inline-m"
                              style={{
                                width: '20px',
                                height: '18px',
                                background: 'hsl(0, 0%, 90%)',
                                borderRadius: '16px'
                              }}
                            />
                            <span
                              style={{
                                width: '300px',
                                height: '18px',
                                background: 'hsl(0, 0%, 90%)',
                                borderRadius: '16px'
                              }}
                            />
                          </div>
                        </div>
                      </Stack.Item>
                    ))}
                  </DelayedRender>
                </Switch.Case>

                <Switch.Case match={[ResourcesListStatus.Empty]}>
                  <Layout variant="off-center" style={{ height: '100%' }} >
                    <Guide
                      slot="content"
                      title="Empty directory"
                      text="This directory is empty, you can add resources by pressing the 'Add' button"
                      style={{ width: '100vw', maxWidth: '350px'  }}
                    />
                  </Layout>
                </Switch.Case>

                <Switch.Case match={[ResourcesListStatus.Success]}>
                  {
                    resources.filter(resource => resource.type === 'directory')
                      .map(resource => (
                        <DropZone
                          key={resource.id}
                          onDrop={onMoveResource(`${resource.location}${resource.name}/`)}
                        >
                          <Stack.Item style={{ flexShrink: 0 }}>
                            <View
                              selectable
                              layout="row"
                              gap="m"
                              style={{
                                borderBottom: '1px solid var(--separator-primary)',
                                padding: '12px 16px 12px 22px',
                                alignItems: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={() => setSearchParams({ location: `${activePath}${resource.name}/` }) }
                            >
                              <Icon2 size="s" name="folder" style={{ fill: 'hsl(0, 0%, 60%)'}} />
                              <Text>{resource.name}</Text>
                            </View>
                          </Stack.Item>
                        </DropZone>
                      ))
                  }

                  {
                    resources.filter(resource => resource.type !== 'directory')
                      .map(resource => (
                        <DropZone.Dragable dragData={resource.id} key={resource.id}>
                          <Stack.Item style={{ flexShrink: 0 }}>
                            <View
                              layout="row"
                              gap="m"
                              selectable
                              style={{
                                borderBottom: '1px solid var(--separator-primary)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '12px 16px 12px 20px'
                              }}
                              onClick={() => setSelectedResourceId(resource.id)}
                            >
                              <View layout="row" alignItems="center" gap="m">

                                {resource.type.startsWith('image') && (
                                  <Image
                                    src={resource?.content?.sizes?.thumbnailSmall || resource?.content?.src}
                                    placeholderSrc={resource?.content?.sizes?.['loader-square-blurred-after'] || resource?.content?.src}
                                    style={{ width: '24px', height: '24px', borderRadius: '25%' }}
                                  />
                                )}

                                {!resource.type.startsWith('image') && (
                                  <Icon2 size="s" name={resourceTemplates[resource.type].icon || 'file'} style={{ fill: 'hsl(0, 0%, 80%)'}} />
                                )}

                                
                                <Text style={{ marginRight: '32px' }}>{typeof resource.name === 'string' && resource.name}</Text>

                              </View>

                              <Button
                                prefix="trash-empty"
                                variant="command-danger"
                                onClick={event => {
                                  event.stopPropagation()
                                  removeResource(resource.id)
                                }}
                              />

                            </View>
                          </Stack.Item>
                        </DropZone.Dragable>

                      ))
                  }
                </Switch.Case>

              </Switch>
            </Stack.Item>

          </Stack>
        </Stack.Item>

        {
          !!selectedResourceId && (
            <Stack.Item style={{ width: '50%', overflowY: 'auto' }}>
              <ResourceFactory
                resourceId={selectedResourceId}
                onClose={() => setSelectedResourceId(undefined)}
              />
            </Stack.Item>
          )
        }

      </Stack>

    </div>
  )
}
