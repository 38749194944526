import React, { useState, useRef } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams
} from 'react-router-dom'
import { useResources } from '@ossy/cms-client-react'
import {
  Input2,
  Title,
  Button,
  View,
  useInputValue,
  Text
} from 'design-system'

export const CreateDirectory = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { workspaceId } = useParams()
  const { createDirectory } = useResources()
  const [directoryName, setDirectoryName] = useInputValue('')
  const [error, setError] = useState()
  const formRef = useRef()
  const directoryLocation = searchParams.get('location') || '/'

  const createAccount = event => {
    event.preventDefault()
    formRef.current.reportValidity() && signUp(email)
      .then(() => setFlowStage(FlowStage.Success))
      .catch(() => setFlowStage(FlowStage.Error))
  }

  const onCreateDirectory = event => {
    event.preventDefault()
    setError()

    formRef.current.reportValidity() && createDirectory({
      name: directoryName,
      location: directoryLocation
    })
      .then(() => {
        setDirectoryName()
        const search = createSearchParams({ location: `${directoryLocation}${directoryName}` })
        navigate(`/${workspaceId}/resources?${search}`)
      })
      .catch(err => {
        setError(err.message)
      })
  }

  return (
    <View gap="s" style={{ height: '100%' }}>

      <Title variant="primary" style={{ marginBottom: 'var(--space-m)' }}>Create a new directory</Title>

      <Text style={{ marginBottom: 'var(--space-m)' }}>
        Create a new directory to organize your resources.
        The new directory will be created in the location: {directoryLocation}
      </Text>

      <form
        ref={formRef}
        onSubmit={createAccount}
        style={{ width: '100%', flexGrow: '1', display: 'flex', flexDirection: 'column', gap: 'var(--space-m)', justifyContent: 'space-between' }}
      >

        <Input2
          id="path"
          type="text"
          placeholder="Directory name"
          value={directoryName}
          onChange={setDirectoryName}
          style={{ width: '100%', marginBottom: 'var(--space-l)' }}
        />

        <div>{ error }</div>


        <View layout="row" style={{ gap: 'var(--space-s)', justifyContent: 'flex-end' }} >

          <Button
            id="cancel"
            onClick={() => navigate('../')}
          >Cancel
          </Button>

          <Button
            id="createDirectory"
            variant="cta"
            onClick={onCreateDirectory}
          >Create directory
          </Button>

        </View>
      </form>

    </View>
  )
}


