import React from 'react'
import { Outlet } from 'react-router-dom'
import { PageSection, Card, Tabs } from 'design-system'

export const DocumentationLayout = ({
  surface = 'primary',
  contentWidth = 'xl',
  cardSurface,
  navigation,
  children,
  content,
}) => (
  <PageSection
    surface={surface}
    maxWidth={contentWidth}
    style={{ padding: 'var(--space-xl) 0' }}
  >
    <Card variant="cover" surface={cardSurface} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <Tabs
        tabs={navigation}
        style={{
          borderBottom: '1px solid var(--separator-primary)',
          height: '56px',
          flexBasis: '100%',
        }}
      />
      <Outlet/>
      { content ?? children ?? <Outlet />}
    </Card>
  </PageSection>
)
