import React, { useMemo } from 'react'
import { css } from 'glamor'

export const Separator = ({
  variant = 'primary',
  as: Element = 'div',
  ...props
}) => {

  const styles = useMemo(() => css({
    borderColor: `var(--separator-${variant})`,
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0'
  }), [variant])

  return <Element {...props} {...styles}/>
}
