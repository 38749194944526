export const restApi = {
  navigation: `
- [Available routes](#overview-of-available-routes)
  `,
  content: `
    # CMS REST API


    # Overview of available routes

    \`\`\`
    // Users
    POST      /api/v0/users/sign-up
    POST      /api/v0/users/sign-in
    GET       /api/v0/users/verify-sign-in
    GET       /api/v0/users/sign-off
    GET       /api/v0/users/me
    GET       /api/v0/users/me/history
    GET       /api/v0/users/me/tokens
    POST      /api/v0/users/me/tokens
    DELETE    /api/v0/users/me/tokens/:tokenId
    \`\`\`

    \`\`\`
    // Workspaces
    POST     /api/v0/workspaces
    GET      /api/v0/workspaces/
    GET      /api/v0/workspaces/:workspaceId
    POST     /api/v0/workspaces/:workspaceId/invitations
    GET      /api/v0/workspaces/:workspaceId/invitations
    DELETE   /api/v0/workspaces/:workspaceId/resource-templates
    \`\`\`

    \`\`\`
    // Resource templates
    POST     /api/v0/workspaces/:workspaceId/resource-templates
    GET      /api/v0/workspaces/:workspaceId/resource-templates
    \`\`\`

    \`\`\`
    // Resources
    GET      /api/v0/workspaces/:workspaceId/resources
    GET      /api/v0/workspaces/:workspaceId/resources/:resourceId
    PUT      /api/v0/workspaces/:workspaceId/resources/:resourceId/name
    PUT      /api/v0/workspaces/:workspaceId/resources/:resourceId/location
    PUT      /api/v0/workspaces/:workspaceId/resources/:resourceId/content
    DELETE   /api/v0/workspaces/:workspaceId/resources/:resourceId
    \`\`\`

  `
}
