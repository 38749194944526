import React from 'react';
import { css } from 'glamor'
import { Title, View, Text, Separator } from 'design-system';
import { useEmployees } from './DataLoader.jsx';

const grid = css({
    width: '100%',
    height: '100%',
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(6, minmax(390px, 425px))',
    gridTemplateRows: '1fr 1fr',
    gap: '24px'
})

const card = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    background: '#fff',
    padding: '32px',
    boxSizing: 'border-box',
})

export const Employees = () => {
    const employees = useEmployees()
    
    return (
        <div {...grid}>

            { employees.map(employee => (
              <View
                inset="l"
                gap="m"
                surface="primary"
                {...card}
              >
                    <Title variant="tertiary">{employee.name}</Title>
                    <Separator />
                    <View gap="s" style={{ flexGrow: '1' }}>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                Id:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {employee.id}
                            </Text>
                        </View>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                Start date:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {employee.startDate}
                            </Text>
                        </View>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                End date:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {employee.endDate || 'ongoing'}
                            </Text>
                        </View>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                ContractId:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                               {employee.contractId}
                            </Text>
                        </View>


                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                            Compensation:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {new Intl.NumberFormat('sv-SE', { style: "currency", currency: "SEK" }).format(employee.monthlySalary)}/month
                            </Text>
                        </View>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                Work hours per day:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                               {new Intl.NumberFormat('sv-SE', { style: "unit", unit: "hour",}).format(employee.workHoursPerDay)} 
                            </Text>
                        </View>

                        <Text style={{ marginBottom: 0 }}>
                            vacations
                        </Text>

                        {employee?.vacations?.map(vacation => (
                            <>
                                <Text style={{ marginBottom: 0 }}>
                                    {vacation.start} - {vacation.end}
                                </Text>
                            </>
                        ))}



                    </View>

                </View>
            ))}

        </div>
    )
}