import React, { useEffect, createContext, useContext } from 'react'
import Analytics from 'react-ga4'
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createHashRouter
} from 'react-router-dom'
import { SDK } from '@ossy/sdk'
import { WorkspaceProvider } from '@ossy/cms-client-react'
import { Theme } from 'design-system'
import { ThemeEditor } from 'connected-components/theme-editor'
import { useNavigate } from './useNavigate.js'
import { defaultAppSettings, useAppSettings } from './AppSettings.jsx'

const AppLoader = () => {
  const { apiUrl, workspaceId, theme, themes, gaId, devMode } = useAppSettings()
  const sdk = SDK.of({ apiUrl, workspaceId })
  const navigate = useNavigate()

  useEffect(() => {
    if (!gaId) return
    if (!window?.location?.pathname) return
    Analytics.initialize(gaId)
    Analytics.send({ hitType: 'pageview', page: window?.location?.pathname })
  }, [gaId])

  const onNavigate = e => {
    const href = e.target.getAttribute('href') || e.target.getAttribute('data-href')
    const hasTarget = e.target.hasAttribute('target')
    const isLocal = (href || '').startsWith('#')
    const isExternalLink = hasTarget || (href || '').includes('.')

    if (!href || ['null', 'undefiend'].includes(href)) return
    if (isExternalLink || isLocal ) return

    e.preventDefault()
    if (gaId) Analytics.send({ hitType: 'pageview', page: href })
    navigate(href)
  }

  return (
    <Theme theme={theme} themes={themes}>
      <WorkspaceProvider sdk={sdk}>
        <div onClick={onNavigate} style={{ display: 'contents' }}>
          <Outlet />
          { devMode && <ThemeEditor />}
        </div>
      </WorkspaceProvider>
    </Theme>
  )
}

export const AppContext = createContext(defaultAppSettings())

export const App = (_appSettings) => {
  const appSettings = { ...defaultAppSettings(), ..._appSettings }

  const appRoutes = [
    {
      path: '/',
      element: <AppLoader />,
      children: appSettings.routes.map(x => {
        if (x.path !== '/') return x
        if (x.path === '/' && !!x.children) return x
        return { ...x, index: true }
      })
    }
  ]

    let router
    appSettings.router === 'hash' && (router = createHashRouter(appRoutes,  { initialEntries, initialIndex }))
    appSettings.router === 'browser' && (router = createBrowserRouter(appRoutes))

  return (
    <AppContext.Provider value={appSettings}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  )
}
