import { useLocation, useNavigate as useNavigateInternal, useMatches, useNavigation } from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import { useAppSettings } from './AppSettings.jsx'

const useParams = (pages) => {
    const location = useLocation()
    const [params, setParams] = useState({})
  
    useEffect(() => {
  
      const getParams = pages => pages.reduce((allParams, page) => {
  
        const activeLocationParts = location.pathname.split('/').filter(x => !!x)
  
        const params = page.path.split('/').filter(x => !!x).reduce((params, part, index) => {
          if (!part.startsWith(':')) return params
          const paramName = part.replace(':', '')
          const paramValue = activeLocationParts[index]
          if (!paramValue) return params
          return {
            ...params,
            [paramName]: paramValue
          }
        }, {})
  
        return { ...allParams, ...params }
  
      }, {})
  
      const params = getParams(pages)
      setParams(params)
  
    }, [location, pages])
  
    return params
  }

export const useNavigate = () => {
  const { routes: _pages } = useAppSettings()
  const pages = new Map(_pages.map(page => [page.id, page]))
  const _navigate = useNavigateInternal()
  const params = useParams(_pages)
  const matches = useMatches()
  const navigation = useNavigation()

  useEffect(() => {console.log('matches', matches)}, [matches])
  useEffect(() => {console.log('navigation', navigation)}, [navigation])

  const navigate = useCallback(href => {
      if (!href.startsWith('@')) return _navigate(href)

      const search = href.split('?')[1]
      const [_, pageId] = href.replace('?' + search, '').split('@')
      const page = pages.get(pageId)
      const pathTemplate = page?.path || '/not-found'
      const path = pathTemplate
          .split('/')
          .map(part => part.startsWith(':') ? params[part.slice(1)] : part)
          .join('/')
      
      _navigate(search ? path + '?' + search : path)

      // todo: build link from link template and insert params
      // take into account the current locale
  }, [_navigate, pages, params])

  return navigate
}

export const useLocale = () => {

    const location = useLocation()
    const [locale, setLocale] = useState('en')

    useEffect(() => {
        const locale = location.pathname.split('/')[1]
        setLocale(locale)
    }, [location])

    return locale

}

// const appendSlash = string => string[string.length - 1] === '/'
//   ? string : `${string}/`

// const prependSlash = string => string[0] === '/'
//   ? string : `/${string}`

// const padWithSlash = string => appendSlash(prependSlash(string))

// export const getUrl = ({ location, pages }) => {
//     // TODO: this needs tests because I don't think it works right
//     const activePathname = location.pathname === '' ? '/' : padWithSlash(location.pathname)
//     const activePathnameParts = activePathname.split('/').filter(x => !!x)

//     const registeredPageRoutes = pages.map(page => padWithSlash(page.location.replace('/pages', '')))
//     const exactRouteMatch = registeredPageRoutes.find(route => route === activePathname)

//     if (!!exactRouteMatch) {
//         return exactRouteMatch
//     }

//     const dynamicRouteMatch = registeredPageRoutes.find(route => (
//     Predicates.byLength(activePathnameParts.length)(route) && Predicates.byParamPlaceHolders(activePathname)(route)
//     ))

//     console.log('dynamicRouteMatch', dynamicRouteMatch)

//     return dynamicRouteMatch
//   }




