import * as moment from 'moment'

export class CompanyClient {

    static of(company) {
        return new CompanyClient(company)
    }

    constructor({ basicInfo = {}, employees = [], contracts = [], vacations = []}) {


        console.log('CompanyClient', basicInfo, employees, contracts)

        if (!basicInfo || !employees || !contracts || !vacations) {
            console.warn('[CompanyClient] Missing data')
        }

        this.employees = employees
        this.contracts = contracts
        this.vacations = vacations

        Object.entries(basicInfo).forEach(([key, value]) => {
            this[key] = value
        })
    }

    get currentFiscalYearStart() {
        return this.getFiscalYears()[0].start
    }

    get currentFiscalYearEnd() {
        return this.getFiscalYears()[0].end
    }

    getFiscalYears() {
        return this.getAllFiscalYearsTo(moment())
    }

    getAllFiscalYearsTo(date) {
        const fiscalYears = []

        let fiscalYearStartDate = moment(this.registrationDate)
        const potentialFiscalYearEnd = moment(fiscalYearStartDate).month(this.financialYearEnd).endOf('month')

        let fiscalYearEnd = potentialFiscalYearEnd.isAfter(fiscalYearStartDate)
            ? moment(potentialFiscalYearEnd)
            : moment(this.registrationDate).add(11, 'months').endOf('month')

        let fiscalYear = 1
        
        while (fiscalYearEnd.isSameOrBefore(date, 'year')) {

            fiscalYears.push({
                fiscalYear,
                start: fiscalYearStartDate.format('YYYY-MM-DD'),
                end: fiscalYearEnd.format('YYYY-MM-DD'),
            })

            fiscalYear = fiscalYear + 1
            fiscalYearStartDate = moment(fiscalYearEnd).add(1, 'month').startOf('month')
            fiscalYearEnd = moment(fiscalYearStartDate).add(11, 'months').endOf('month')
        }
        return fiscalYears.sort((a, b) => b.start.localeCompare(a.start))
    }

    getFiscalYear(fiscalYearInNumber) {
        const companyRegistrationDate = moment(this.registrationDate)
        
    }
}