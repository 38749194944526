import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import exifr from 'exifr'
import { useResources } from '@ossy/cms-client-react'
import { Title, Button, View, Text, Upload } from 'design-system'

const FlowStage = {
  Error: 'Error',
  Preview: 'Preview',
  Uploading: 'Uploading',
  Done: 'Done'
}

export const UploadImage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = searchParams.get('location')
  const { uploadFile } = useResources()
  const [files, setFiles] = useState([])
  const [error, setError] = useState()
  const flowStage = getFlowStage(files)

  const uploadFiles = () => {
    setFiles(files => files.map(file => ({ ...file, status: 'uploading' })))

    const uploadRequests = files.map((file) => 
      uploadFile(location, file.file)
        .then(() => ({...file, status: 'uploaded' }))
        .catch(() => ({...file, status: 'error' }))
    )

    return Promise.all(uploadRequests)
      .then(setFiles)
  }

  const onUserInput = (e) => {
    setFiles([])
    Array.from(e.target.files).forEach(file => {
      Promise.all([
        exifr.thumbnailUrl(file),
        exifr.parse(file, true)
      ])
        .then(([thumbnailUrl, metadata]) => setFiles(files => [...files, { file, metadata, thumbnailUrl, status: 'preview' }]))
        .catch(err => console.log(err))
    })
  }

  const onDone = () => {
    setFiles([])
    setError()
    navigate('../')
  }

  return (
    <>
      <Title variant="primary" style={{ marginBottom: 'var(--space-m)' }}>
        Upload files
      </Title>

      {

        files.map(({ thumbnailUrl, metadata, status }) => (
          <View layout="row" gap="m">

          {
            !!thumbnailUrl ? (
              <View
                as="img"
                src={thumbnailUrl}
                width="80px"
                height="80px"
                style={{ backgroundSize: 'cover', borderRadius: 'var(--space-s)' }}
              />
            ) : (
              <View
                style={{
                  background: 'hsl(0, 0%, 90%)',
                  backgroundSize: 'cover',
                  borderRadius: 'var(--space-s)',
                  width: "80px",
                  height: "80px"
                }}
              />
            )
          }
  
          <View gap="xs">
            <Text style={{ margin: '0' }}>{metadata.Title?.value || metadata.ObjectName || 'Untitled'}</Text>
            <Text style={{ margin: '0' }}>{metadata.ImageDescription}</Text>
            <View layout="row-wrap" style={{ gap: 'var(--space-s)' }}>
              {metadata?.Keywords?.map(x => <Button variant="tag">{x}</Button>)}
            </View>
            <Text>{status}</Text>
          </View>
  
        </View>
        ))
      }

      <Upload
        type="file"
        accept="image/png, image/jpeg"
        multiple
        onChange={onUserInput}
        style={{ flexGrow: '1', marginBottom: 'var(--space-m)' }}
      />

      <div>{ error }</div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 'var(--space-m)' }}>
        <Button
          id="cancel"
          onClick={onDone}
        >Cancel
        </Button>

        {
          flowStage === FlowStage.Preview && (
            <Button
              id="upload"
              variant="cta"
              onClick={uploadFiles}
            >Upload
            </Button>
          )
        }
        
        {
          flowStage === FlowStage.Uploading && (
            <Button
              id="upload"
              variant="cta"
              disabled
            >Upploading...
            </Button>
          )
        }

        {
          flowStage === FlowStage.Error && (
            <Button
              id="upload"
              variant="cta"
              onClick={uploadFiles}
            >Retry failed uploads
            </Button>
          )
        }

        {
          flowStage === FlowStage.Done && (
            <Button
              id="upload"
              variant="cta"
              onClick={onDone}
            >Done
            </Button>
          )
        }
      </div>
    </>
  )
}

function getFlowStage(files = []) {

  if (files.length === 0) return FlowStage.Preview

  const didSomeUploadsFail = files.some(({ status }) => status === 'error')
  if (didSomeUploadsFail) return FlowStage.Error

  const areSomeUploadsInProgress = files.some(({ status }) => status === 'uploading')
  if (areSomeUploadsInProgress) return FlowStage.Uploading

  const areAllFilesUploadedSuccessfully = files.every(({ status }) => status === 'uploaded')
  if (areAllFilesUploadedSuccessfully) return FlowStage.Done

  return FlowStage.Preview
}