import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthentication, AuthenticationStatus } from '@ossy/cms-client-react'
import { Header, Footer, Page } from 'design-system'
import { Logo } from './Logo.jsx'

const navigation = [
  { id: 'start-nav', label: 'Start', href: '/' },
  // { id: 'features-nav', label: 'Features', href: '#features' },
  { id: 'guides-nav', label: 'Guides', href: '/documentation/guides' },
  { id: 'references-nav', label: 'References', href: '/documentation/references' }
]

const signedOutHeaderActions = [
  {
    key: 'log-in',
    children: 'Login',
    href: '/sign-in',
    prefix: 'log-in'
  },
  {
    key: 'sign-up',
    children: 'Sign up',
    href: '/sign-up',
    variant: 'cta'
  }
]

const signedInHeaderActions = [
  {
    key: 'workspaces',
    prefix: 'controller',
    children: 'My workspaces',
    href: '/profile/workspaces',
    variant: 'cta'
  }
]

export const PublicPagesLayout = ({
  headerSurface = 'primary',
  footerSurface = 'primary',
  content,
  children
}) => {
  const { status } = useAuthentication()

  const headerActions = status === AuthenticationStatus.Authenticated
    ? signedInHeaderActions
    : signedOutHeaderActions

  const sections = [
    {
      id: "header",
      slot: "header",
      surface: headerSurface,
      style: {
        position: 'sticky',
        top: '0px',
        width: '100%',
        zIndex: '20',
        padding: 'var(--space-m) var(--space-l)'
      },
      render: () => (
        <Header
          logo={<Logo />}
          navigation={navigation}
          actions={headerActions}
        />
      )
    },
    {
      id: "outlet",
      slot: "content",
      maxWidth: 'full',
      render: () => content ?? children ?? <Outlet />
    },
    {
      id: "footer",
      slot: "footer",
      surface: footerSurface,
      style: {
        padding: 'var(--space-l) var(--space-m)',
        borderTop: '1px solid var(--separator-primary)'
      },
      render: () => (
        <Footer surface={footerSurface} columns={[
          {
            title: 'About',
            body: 'This is a proof of concept SaaS CMS System built by Ossy.se'
          },
          {
            title: 'Navigation',
            list: navigation
          },
          {
            title: 'Contact',
            list: [
              { label: 'ossy.se', href: 'https://ossy.se/' },
              { label: 'yourfriends@ossy.se', href: 'mailto:yourfriends@ossy.com' },
            ]
          },

        ]}/>
      )
    }
  ]

  return <Page maxWidth="xl" layout="page" surface="base" sections={sections} />
}
