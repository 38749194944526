import React from 'react';
import { css } from 'glamor'
import { Title, View, Text, Separator } from 'design-system';
import { useForecast } from '../DataLoader.jsx';

const grid = css({
    width: '100%',
    height: '100%',
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(6, minmax(390px, 1fr))',
    gridTemplateRows: '1fr 1fr',
    gap: '1px',
    overflowX: 'auto',
})

const calendarGrid = css({
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(7, 40px)',
    gridAutoRows: 'repeat(6, 40px)',
    gap: '8px',
    justifyItems: 'center',
    alignItems: 'center',
})

const calendarDay = css({
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '999px',
    padding: '8px',

    '&:hover': {
        backgroundColor: '#ddd'
    },

    '[data-day-off="true"]': {
        backgroundColor: '#f8efef'
    }
})

const activeGlow = css({
    '[data-isCurrentMonth="true"]': {
        boxShadow: '0 0 0 1px var(--color-accent)',
    }
})

export const Forecast = () => {
    const months = useForecast()
    
    return (
        <div {...grid}>

            {months.map(month => (
                <View
                    inset="l"
                    gap="m"
                    surface="primary"
                >
                    <Title variant="tertiary">{month.year} {month.month}</Title>
                    <Separator {...activeGlow} data-isCurrentMonth={month.isCurrentMonth} variant={month.isCurrentMonth ? 'accent' : 'primary'} />
                    <View gap="s" style={{ flexGrow: '1' }}>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                Income:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {new Intl.NumberFormat('sv-SE', { style: "currency", currency: "SEK" }).format(month.projectedIncome)}
                            </Text>
                        </View>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                Salary cost:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {new Intl.NumberFormat('sv-SE', { style: "currency", currency: "SEK" }).format(month.monthlyCostOfEmployees)}
                            </Text>
                        </View>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                Revenue:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                {new Intl.NumberFormat('sv-SE', { style: "currency", currency: "SEK" }).format(month.revenue)}
                            </Text>
                        </View>

                        <View layout="row" gap="s" style={{ justifyContent: 'space-between'}}>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                                Hours worked:
                            </Text>
                            <Text variant="m" style={{ marginBottom: 0 }} >
                               {new Intl.NumberFormat('sv-SE', { style: "unit", unit: "hour",}).format(month.hoursWorked)} 
                            </Text>
                        </View>

                    </View>

                    <div {...calendarGrid}>

                        {Array.from(Array(month.days[0].isoWeekday - 1).keys()).map(() => (
                            <div></div> 
                        ))}

                        {month.days.map(day => (
                            <Text
                                variant="s"
                                {...calendarDay}
                                data-day-off={!day.isWorkday}
                                style={{ margin: 0}}
                            >
                                {day.monthDay}
                            </Text>
                        ))}

                        {Array.from(Array(7 - month.days[0].isoWeekday).keys()).map(() => (
                            <div></div>   
                        ))}

                    </div>
                </View>
            ))}
        </div>
    )
}