import React from 'react'
import { Layout } from 'design-system'
import { MarkdownViewer } from 'design-system-extras'
import { concepts } from './concepts.js'
import { reactHooks } from './react-hooks.js'
import { restApi } from './rest-api.js'
import { gettingStarted } from './getting-started.js'
import { uploadResourceTemplates } from './upload-resource-templates.js'

const docs = {
  concepts,
  reactHooks,
  restApi,
  gettingStarted,
  uploadResourceTemplates
}

export const Documentation = ({ about }) =>
  <Layout variant="sidebar" style={{ height: '100%' }}>

    <aside
      slot="sidebar-primary"
      style={{
        padding: 'var(--space-l)',
        borderRight: '1px solid var(--separator-primary)',
        overflow: 'auto',
        minHeight: '100%',
      }}>
      <MarkdownViewer children={docs[about]?.navigation} />
    </aside>

    <main
      slot="content"
      style={{
        padding: 'var(--space-l)',
        overflow: 'auto',
        minHeight: '100%',
      }}>
      <div style={{ maxWidth: '1200px' }}>
        <MarkdownViewer children={docs[about]?.content} />
      </div>
    </main>

  </Layout>
