import * as moment from 'moment'
import Holidays from 'date-holidays'

export class CalendarClient {

static isWorkDay(date, locale = 'SE') {
    const hd = new Holidays(locale)
    const holidays = hd.getHolidays('2024')
        .filter(x => ['public', 'bank', 'optional'].includes(x.type))
        .map(x => moment(x.date))

    const isPublicHoliday = holidays.some(holiday => holiday.isSame(date))
    const isWeekend = [6, 7].includes(date.isoWeekday()) // filter out saturdays and sundays

    return !(isPublicHoliday || isWeekend)
}

static getAllDaysInAYear(year) {
    const startOfYear = moment(year).startOf('year')
    const endOfYear = moment(year).endOf('year')
    return CalendarClient.getAllDaysInDateSpan(
        startOfYear.format('YYYY-MM-DD'),
        endOfYear.format('YYYY-MM-DD')
    )
}

static getAllDaysInDateSpan(startDate, endDate) {
    const start = moment(startDate)
    const end = moment(endDate)
    
    const days = [start]
    
    let notEnd = true
    while (notEnd) {
        const day = moment(days[days.length - 1]).add(1, 'day')
        days.push(day)
    
        if (day.isSame(end, 'day')) {
        notEnd = false
        }
        
    }
    
    return days
}

}