import { useResource, AsyncStatus } from '@ossy/cms-client-react'
import {
  Guide,
  Text,
  DelayedRender
} from 'design-system'

import { DocumentResource } from './DocumentResource.js'
import { ImageResource } from './ImageResource'

export const ResourceFactory = ({
  resourceId,
  onClose: _onClose
}) => {
  const { resource, status } = useResource(resourceId)

  if (AsyncStatus.Error === status) {
    return (
      <Guide
        title="Not found"
        text="We can't find the resource you are looking for"
        style={{ padding: '40px 16px' }}
      />
    )
  }

  if ([AsyncStatus.Loading, AsyncStatus.NotInitialized].includes(status)) {
    return (
      <DelayedRender>
        <Text>loading...</Text>
      </DelayedRender>
    )
  }

  if (['image/jpeg', 'image/png'].includes(resource.type)) {
    return (
      <ImageResource
        resourceId={resourceId}
        onClose={_onClose}
      />
    )
  }

  return (
    <DocumentResource
      resourceId={resourceId}
      onClose={_onClose}
    />
  )

}
