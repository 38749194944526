export const concepts = {
  navigation: `
- [Resource](#resource)
- [Resource templates](#resource-templates)
- [Workspace](#workspace)
  `,
  content: `
# Concepts

The theory we have built this CMS on is that content creators want to use
an UI to manage content whereas developers want to have everything written in code.

There are three main concepts you need to understand.
Resources, resource templates, and workspaces.

## Resource

Resources are managed in the UI and is the actual content developers can then
fetch through our REST API or React bindings.

## Resource templates

Resource templates are defined in JSON and live in your code repository.
They describe the shape of resources and what fields are shown in the UI.

## Workspace

A workspace is just an encapsulation of resources, resource templates,
billing information, settings/configurations, and what users have access to modify those.
You might for example want to use different workspaces for different website
you use our CMS for.
  `
}
