import React from "react"
import { useQuery } from "@ossy/cms-client-react"
import { Stack, Text, Title, Tag, View, Button } from "design-system"
import { css } from 'glamor'

const statusStyle = css({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  background: 'var(--background)',

  '[data-status="queued"]': {
    '--background': 'hsl(230, 100%, 50%)',
  },

  '[data-status="failed"]': {
    '--background': 'hsl(339, 100%, 50%)',
  },

  '[data-status="success"]': {
    '--background': 'hsl(125, 47%, 50%)',
  },

})

export const VisualContentDescriptors = ({ resourceId }) => {

    const { status, resources } = useQuery({
        type: '@ossy/jobs/visual-content-descriptors',
        'content.resourceId': resourceId,
    })

    if (status !== 'Success') return null

    const latestJob = resources?.[0]

    if (!latestJob) return null

    return (
        <Stack.Item>

          <View layout="row" style={{ padding: '8px', marginBottom: '8px', justifyContent: 'space-between', alignItems: 'center' }}>

            <Text>Visual content descriptors</Text>
            
            <View layout="row" style={{ alignItems: 'center', gap: '8px' }}>
              <Text variant="small" maxWidth='m' style={{ color: 'hsl(0, 0%, 40%)' }}>{latestJob?.content?.status}</Text>
              <div {...statusStyle} data-status={latestJob?.content?.status} />
              <Button variant="command" prefix="push-chevron-down"  />
            </View>

            
          </View>

          <div style={{ padding: '8px' }}>
            <Title>{latestJob?.content?.title}</Title>
            <Text variant="small" maxWidth='m' style={{ color: 'hsl(0, 0%, 30%)', marginBottom: '8px' }}>{latestJob?.content?.description}</Text>
            <div style={{ display: 'flex', gap: 'var(--space-xs)', flexWrap: 'wrap' }}>
              {latestJob?.content?.result?.tags?.map(tag => <Tag key={tag}>{tag}</Tag>)}
            </div>
          </div>
        </Stack.Item>
      )

}